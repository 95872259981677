<template>
  <div class="container">
    <div class="header" :class="{ open: open }">
      <p>{{ year }}</p>
      <BaseIcon
        name="drop_down"
        color="white"
        class="icon"
        :class="{ rotated: open }"
        @clicked="toggleOpen"
      />
    </div>
    <div v-if="open" class="reports-container">
      <BaseReportLink
        v-for="report in reports"
        :key="report.report_uid"
        :report="report"
        @clicked="editReport(report)"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "YearFolder",
  props: {
    year: {
      type: String,
      required: true
    },
    reports: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      open: false
    };
  },
  methods: {
    editReport(report) {
      this.$emit("edit", report);
    },
    toggleOpen() {
      this.open = !this.open;
    }
  }
};
</script>

<style scoped lang="scss">
.container {
  margin-bottom: 1.5em;

  .header {
    cursor: pointer;
    height: 2.5rem;
    background-color: $color-gray;
    color: $color-white;
    width: 100%;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
    box-sizing: border-box;

    &.open {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }

  .icon {
    // transition: all 0.4s;
    &.rotated {
      transform: rotateX(180deg);
    }
  }

  .link-container {
    margin-bottom: 0.5em;
    width: 95%;
  }

  .reports-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: $color-gray;
    border-radius: 0 0 0.5rem 0.5rem;
  }
}
</style>
