<template>
  <InterfaceForm
    :form-name="formName"
    :title="title"
    :submit-button="submitButton"
    @submit="submitHandler"
    @close="closeHandler"
  />
</template>

<script>
import { mapActions } from "vuex";
import InterfaceForm from "@/components/InterfaceForm.vue";

export default {
  name: "ViewSignup",

  components: { InterfaceForm },

  data() {
    return {
      formName: "signup",
      title: "Registrazione",
      submitButton: {
        label: "Registrati"
      }
    };
  },

  methods: {
    ...mapActions(["signup", "setLoading"]),

    submitHandler(data) {
      // console.log("submit: ", data);
      this.signup(data)
        .then(() => {
          this.$root.$emit("modal", {
            color: "main",
            fullscreen: "true",
            title: "Grazie per esservi registrati. Copernico Milano vi contatterà al più presto",
            confirmLabel: "vai alla home",
            colorConfirm: {
              color: "white",
              transparent: true
            },
            confirm: () => {
              this.$router.push({ name: "home" });
            },
          })
        })
        .catch(error => {
          this.setLoading(false);
          switch (error.code) {
            case "auth/email-already-in-use":
              this.$root.$emit("modal", {
                title: "errore: l'email inserita è già associata ad un utente"
              });
              break;
            default:
              this.$root.$emit("modal", {
                title: "errore generico nella registrazione."
              });
              break;
          }
        });
    },
    closeHandler() {
      this.$root.$emit("modal", {
        title: "Sicuro di voler uscire?",
        confirm: () => {
          this.$router.push({ name: "home" });
        }
      });
    }
  }
};
</script>

<style scoped></style>
