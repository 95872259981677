<template>
  <div id="login" class="page">
    <div class="body">
      <div class="info-container info-slide--content-limited">
        <img id="logo" src="@/assets/logo_mi.png" />
        <h1 class="text--gray">
          Copernico, la web app per il Pronto Intervento Ambientale
        </h1>
        <FormulateForm
          v-model="formValues"
          class="login-form"
          @submit="submitHandler"
        >
          <div v-show="loginStep == 0" class="login-step">
            <BaseButton
              label="Accedi"
              color="dark"
              size="medium"
              @clicked="next"
            />
          </div>
          <div v-show="loginStep == 1" class="login-step">
            <div>
              <FormulateInput
                name="username"
                type="text"
                label="Username/email"
                placeholder="Your username or email"
                validation="required"
              />
            </div>
            <div>
              <BaseIcon
                name="go_to"
                :color="usernameNotEmpty ? 'main' : 'gray'"
                @clicked="next"
              />
            </div>
          </div>
          <div v-show="loginStep == 2" class="login-step">
            <div class="login-step-inner">
              <div>
                <FormulateInput
                  name="password"
                  type="password"
                  label="Password"
                  placeholder="Password"
                  validation="required"
                />
              </div>
              <div>
                <BaseIcon
                  name="go_to"
                  :color="passwordNotEmpty ? 'main' : 'gray'"
                  @clicked="submitHandler"
                />
              </div>
            </div>
            <p class="link" @click="resetPassHandler">Reset password</p>
          </div>
          <div v-show="loginStep == 3" class="login-step">
            <p>
              Lo username/email inserito non è ancora associato a nessun utente.
              Registrarsi per proseguire.
            </p>
            <BaseButton label="Registrati" color="error" @clicked="signup" />
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "ViewLogin",

  data() {
    return {
      formValues: {},
      loginStep: 0
    };
  },

  computed: {
    ...mapGetters(["isAuthenticated"]),
    usernameNotEmpty() {
      return this.formValues.username && this.formValues.username != "";
    },
    passwordNotEmpty() {
      return this.formValues.password && this.formValues.password != "";
    }
  },

  mounted() {
    this.loginStep = 0;
    if (this.isAuthenticated) {
      let next = this.$route.params.nextName;
      this.$router.push({ name: next ? next : "home" });
    }
    window.addEventListener("keyup", this.keyupHandler);
  },

  beforeDestroy() {
    window.removeEventListener("keyup", this.keyupHandler);
  },

  methods: {
    ...mapActions([
      "login",
      "loginUser",
      "setLoading",
      "sendPasswordResetEmail"
    ]),

    next() {
      if (this.loginStep == 0) {
        this.loginStep++;
      } else if (this.loginStep == 1) {
        if (this.formValues.username && this.formValues.username != "") {
          this.loginUser(this.formValues.username).then(res => {
            // console.log("ritorno da loginUser", res);
            if (res.result) {
              this.$set(this.formValues, "email", res.email);
              this.loginStep = 2;
            } else {
              this.loginStep = 3;
            }
          });
        }
      }
    },

    submitHandler() {
      // console.log("submit - data:", data);
      let data = {
        email: this.formValues.email,
        password: this.formValues.password
      };
      if (data.email && data.password) {
        this.login({ email: data.email, password: data.password }).catch(
          error => {
            this.setLoading(false);
            switch (error.code) {
              case "auth/wrong-password":
                this.$root.$emit("modal", {
                  title: "errore: password errata"
                });
                break;
              case "auth/too-many-requests":
                this.$root.$emit("modal", {
                  title: "errore: troppi tentativi per questo account."
                });
                break;
              default:
                this.$root.$emit("modal", {
                  title: "errore generico di accesso."
                });
                break;
            }
          }
        );
        // redirect moved to App.vue
        // .then(() => {
        //   let next = this.$route.params.nextName;
        //   this.$router.push({ name: next ? next : "home" });
        // });
      } else {
        console.error("missing username, email or password");
      }
    },

    signup() {
      this.$router.push({ name: "signup" });
    },

    keyupHandler(event) {
      event.stopPropagation();
      if (event.keyCode === 13) {
        this.enterHandler();
      }
    },

    enterHandler() {
      if (this.loginStep == 2) {
        this.submitHandler();
      } else if (this.loginStep == 3) {
        this.signup();
      } else {
        this.next();
      }
    },
    resetPassHandler() {
      this.$root.$emit("modal", {
        title: `Verrà inviata una email per il reset della password all'indirizzo:\n\n${this.formValues.email}\n`,
        confirm: () => {
          this.sendPasswordResetEmail(this.formValues.email);
        },
        undo: () => {},
        confirmLabel: "conferma",
        colorConfirm: {
          color: "main",
          transparent: false
        },
        undoLabel: "annulla",
        colorUndo: {
          color: "main",
          transparent: true
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
// #login-wrapper {
//   display: flex;
//   justify-content: center;
// }
// #login {
//   padding-top: 2vh;
//   height: 100%;
//   max-width: $max-width;
// }
#login {
  .body {
    background-color: $color-light;
    padding-top: 1.3rem;
  }
  .login-form {
    margin-top: 2em;
  }
  h1 {
    margin-bottom: 3.5rem;
  }
  .login-step {
    width: 100%;
  }
  .login-step:first-child {
    display: flex;
    justify-content: center;
  }
  .login-step:nth-child(2),
  .login-step:nth-child(3) .login-step-inner {
    display: flex;
    justify-content: flex-end;
  }
  .login-step:nth-child(2) > div:first-child,
  .login-step:nth-child(3) .login-step-inner > div:first-child {
    flex: 1;
  }
  .login-step:nth-child(2) > div:last-child,
  .login-step:nth-child(3) .login-step-inner > div:last-child {
    position: relative;
    margin-left: 0.5em;
    top: 2.1em;
  }
  .login-step:last-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .login-step:last-child > p {
    margin: 0 0 1em 0;
  }
  #logo {
    width: 50%;
    margin-bottom: 4.87em;
  }
  .link {
    font-size: 0.7rem;
    color: $color-black;
    text-decoration: underline;
    cursor: pointer;
  }
}
</style>
