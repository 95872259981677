import Vue from "vue";
import VueRouter from "vue-router";
import ViewHome from "../views/ViewHome.vue";
import ViewLogin from "../views/ViewLogin.vue";
import ViewReport from "../views/ViewReport.vue";
import ViewProfile from "../views/ViewProfile.vue";
import ViewSignup from "../views/ViewSignup.vue";
import store from "../js/store.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: {
      name: "home"
    }
  },
  {
    path: "/home",
    name: "home",
    component: ViewHome
  },
  {
    path: "/login",
    name: "login",
    component: ViewLogin
  },
  {
    path: "/form",
    name: "form",
    component: ViewReport,
    props: true
  },
  {
    path: "/profile",
    name: "profile",
    component: ViewProfile
  },
  {
    path: "/signup",
    name: "signup",
    component: ViewSignup
  }
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  // for each navigation check the login status
  // set the destination page name as a param of the login route
  if (
    to.name !== "login" &&
    to.name !== "signup" &&
    !store.getters.isAuthenticated
  )
    router.push({
      name: "login",
      params: { nextName: to.name }
    });
  else next();
});

router.afterEach(() => {
  window.scrollTop = 0;
});

export default router;
