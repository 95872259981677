<template>
  <label
    :for="context.id"
    class="formulate-input-label"
    :class="{ colored: isRequired }"
  >
    {{ context.label }}
    <span v-if="isRequired" class="required-mark"> (*)</span>
  </label>
</template>

<script>
export default {
  props: {
    context: {
      type: Object,
      required: true
    }
  },
  computed: {
    isRequired() {
      // console.log("rules", this.context);
      return (
        this.$route.name != "login" &&
        this.context.rules.findIndex(
          rule => rule.ruleName == "required" || rule.ruleName == "location"
        ) >= 0
      );
    }
  }
};
</script>

<style scoped lang="scss">
.required-mark {
  color: $color-error;
  font-weight: bold;
}
</style>
