<template>
  <InterfaceForm
    v-if="formName"
    :form-name="formName"
    :title="title"
    :submit-button="submitButton"
    :prefill-data="report"
    :header-color="headerColor"
    :force-sub-obj="forceSubmitObject"
    @submit="submitHandler"
    @close="closeHandler"
    @save="saveHandler"
  />
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InterfaceForm from "@/components/InterfaceForm.vue";
import { v4 as uuidv4 } from "uuid";
// import _ from "lodash";

export default {
  name: "ViewReport",

  components: { InterfaceForm },

  props: {
    new: {
      type: Boolean,
      required: true
    },
    report: {
      type: Object,
      default: undefined,
      validator: function(val) {
        return val["report_uid"] != undefined;
      }
    }
  },

  data() {
    return {
      title: "Segnalazione",
      submitButton: {
        label: "Invia!"
      },
      reportUid: null,
      headerColor: undefined,
      formName: null,
      forceSubmitObject: {
        flag: false,
        payload: null
      }
    };
  },

  computed: {
    ...mapGetters(["user"])
  },

  mounted() {
    //initialization
    if (this.new) {
      this.reportUid = uuidv4();
      this.title = "Attivazione preliminare";
      this.formName = "preliminare";
      // this.headerColor = "gray";
    } else {
      this.reportUid = this.report.report_uid;
      switch (this.report.stato) {
        case "APD":
          this.headerColor = "error";
          this.title = "Attivazione preliminare";
          this.formName = "preliminare";
          break;
        case "AP":
          this.headerColor = "main";
          this.title = "Attivazione";
          this.formName = "attivazione";
          break;
        case "AD":
          this.headerColor = "main";
          this.title = "Attivazione";
          this.formName = "attivazione";
          break;
        case "A":
          this.headerColor = "dark";
          this.title = "Attivazione";
          this.formName = "attivazione";
          break;
        default:
          console.error("missing report status", this.report);
          break;
      }
    }
    this.setCurrentReportCode(this.reportUid);
  },

  methods: {
    ...mapActions(["submitReport", "setCurrentReportCode"]),
    submitHandler(data) {
      if (!this.forceSubmitObject.flag) {
        let payload = this.getPayload(
          data,
          this.formName == "attivazione" ? "A" : "AP"
        );
        if (payload.stato == "A") {
          console.log('here we are', this.formName)
          this.$root.$emit("modal", {
            color: "main",
            fullscreen: "true",
            title:
              "Con l’invio della segnalazione si dà incarico a Copernico Milano per le attività di pronto intervento ambientale secondo i termini del contratto.",
            confirmLabel: "conferma invio",
            alignLeft: true,
            colorConfirm: {
              color: "white",
              transparent: true
            },
            confirm: () => {
              this.sendPayload(payload).then(() => {
                this.$root.$emit("modal", {
                  color: "dark",
                  fullscreen: "true",
                  title: "Segnalazione inviata !",
                  confirmLabel: "torna alla home",
                  colorConfirm: {
                    color: "white",
                    transparent: true
                  },
                  confirm: () => {},
                  close: () => {
                    this.$root.$emit("open-report", payload.report_uid);
                  }
                });
                this.$router.push({ name: "home" });
              });
            }
          });
        } else if (payload.stato == "AP") {
          this.sendPayload(payload).then(() => {
            // if (payload.stato == "AP") {
            this.$root.$emit("modal", {
              color: "main",
              fullscreen: "true",
              title:
                "Copernico ha ricevuto la prima segnalazione.\n\nSi prega di completare i dati al più presto.",
              confirmLabel: "completa segnalazione",
              alignLeft: true,
              colorConfirm: {
                color: "white",
                transparent: true
              },
              confirm: () => {
                this.$root.$emit("open-report", payload.report_uid);
              }
            });
            this.$router.push({ name: "home" });
            // } else if (payload.stato == "A") {
            // this.$root.$emit("modal", {
            //   color: "dark",
            //   fullscreen: "true",
            //   title: "Segnalazione inviata !",
            //   confirmLabel: "torna alla home",
            //   colorConfirm: {
            //     color: "white",
            //     transparent: true
            //   },
            //   confirm: () => {},
            //   close: () => {
            //     this.$root.$emit("open-report", payload.report_uid);
            //   }
            // });
            // this.$router.push({ name: "home" });
            // }
          });
        }
      } else {
        this.forceSubmitObject.flag = false;
        this.forceSubmitObject.payload = null;
        this.sendPayload(data).then(() => {
          if (data.stato == "APD" || data.stato == "AD") {
            this.$root.$emit("modal", {
              color: data.stato == "APD" ? "error" : "main",
              fullscreen: "true",
              title: "Draft salvato !",
              confirmLabel: "torna alla home",
              colorConfirm: {
                color: "white",
                transparent: true
              },
              confirm: () => {},
              close: () => {
                this.$root.$emit("open-report", data.report_uid);
              }
            });
            this.$router.push({ name: "home" });
          }
        });
      }
    },
    closeHandler(data) {
      let keys = Object.keys(data);
      if (!keys.length) {
        // if no input is given, don't show the confirm modal
        this.$router.push({ name: "home" });
      } else {
        this.$root.$emit("modal", {
          title: "Vuoi salvare i dati inseriti prima di chiudere?",
          confirmLabel: "Sì, salva dati",
          undoLabel: "No, non salvare",
          confirm: () => {
            this.save(data);
          },
          undo: () => {
            this.$router.push({ name: "home" });
          },
          colorConfirm: {
            color: "main"
          },
          colorUndo: {
            color: "main",
            transparent: true
          }
        });
      }
    },
    save(data) {
      let payload = this.getPayload(
        data,
        this.formName == "attivazione" ? "AD" : "APD",
        true //withoutData
      );
      //#############forceSubmit##############
      // override formValues in InterfaceForm
      // and trigger submit
      this.forceSubmit(payload);
      // replace the following:
      // this.sendPayload(payload).then(() => {
      //   this.$router.push({ name: "home" });
      // });
      //######################################
    },

    forceSubmit(payload) {
      // console.log("forceSubmit in ViewReport, payload: ", _.cloneDeep(payload))
      this.forceSubmitObject["payload"] = payload;
      this.forceSubmitObject["flag"] = true;
    },

    sendPayload(payload) {
      return this.submitReport(payload);
    },
    fixPictures(pictures) {
      let newOne = [];
      if (pictures) {
        pictures.forEach(pic => {
          if (pic.relativeUrl) {
            pic.url = pic.relativeUrl;
          }
          if (pic.url) {
            newOne.push([pic]);
          } else {
            newOne.push(pic);
          }
        });
      }
      return newOne;
    },
    getPayload(data, status, withoutData) {
      let nDate = new Date().toLocaleString('en-US', {
        timeZone: 'Europe/Rome'
      })
      let timestamp = new Date(nDate).getTime();
      if (data.pictures && Array.isArray(data.pictures))
        data.pictures = this.fixPictures(data.pictures);

      let payload = {};
      if (withoutData) {
        payload = {
          user_uid: this.user.uid,
          report_uid: this.reportUid,
          timestamp: timestamp,
          stato: status
        };
      } else {
        payload = {
          ...data,
          user_uid: this.user.uid,
          report_uid: this.reportUid,
          timestamp: timestamp,
          stato: status
        };
      }
      return payload;
    },
    saveHandler(data) {
      this.save(data);
    }
  }
};
</script>

<style scoped></style>
