var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.active)?_c('div',{staticClass:"page terms",attrs:{"id":"service_terms_cont"}},[_c('a',{attrs:{"id":"home_link","href":_vm.homeLink}},[_c('img',{attrs:{"id":"terms_logo","src":require("@/assets/logo_mi.png")}})]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_c('div',{staticClass:"close"},[_c('BaseIcon',{attrs:{"name":"close_thin","color":'dark',"fill":true},on:{"clicked":_vm.close}})],1),_vm._m(4),_vm._m(5)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"bonifica_cont"}},[_c('img',{attrs:{"id":"bonifica","src":require("@/assets/box-bonifica.jpg")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"intro_style"},[_c('b',[_vm._v("Copernico Milano s.r.l.")]),_vm._v(" è una struttura nata dall’esperienza di professionisti e imprese da anni impegnati in campo ambientale. Ci occupiamo di attività di progettazione, consulenza, coordinamento e direzione lavori in tutti gli ambiti della tutela ambientale a favore di privati, aziende ed Enti pubblici. Offriamo un servizio di PIA (Pronto Intervento Ambientale) per la messa in sicurezza d’emergenza e la bonifica delle aree coinvolte da sversamenti di sostanze pericolose (a seguito di incidenti stradali o nei cicli produttivi industriali). ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"style_box"},[_c('div',{staticClass:"style_box_cont"},[_c('span',{staticClass:"style_title"},[_vm._v("PRONTO INTERVENTO AMBIENTALE")]),_c('div',{staticClass:"style_body"},[_vm._v(" Dopo aver contrattualizzato il servizio, esso è attivo 24h/24h su tutto il territorio nazionale con tempestiva attivazione di una squadra PIA ")])]),_c('div',{staticClass:"style_box_cont"},[_c('span',{staticClass:"style_title"},[_vm._v("COME SI ATTIVA")]),_c('div',{staticClass:"style_body"},[_vm._v(" Tramite segnalazione diretta su webapp dedicata, oppure tramite call telefonica ed invio del verbale di segnalazione per email ")])]),_c('div',{staticClass:"style_box_cont"},[_c('span',{staticClass:"style_title"},[_vm._v("QUANDO SI ATTIVA")]),_c('div',{staticClass:"style_body"},[_vm._v(" A seguito di sversamento di prodotti potenzialmente contaminanti, compresi carburanti e oli idraulici ")])]),_c('div',{staticClass:"style_box_cont"},[_c('span',{staticClass:"style_title"},[_vm._v("COME E DOVE OPERIAMO")]),_c('div',{staticClass:"style_body"},[_vm._v(" Le squadre PIA sono distribuite capillarmente sul territorio e sono dotate delle autorizzazioni necessarie ad operare nei differenti ambiti. Una rete nazionale di professionisti garantisce il coordinamento diretto delle attività di cantiere. ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('img',{attrs:{"id":"terms_schema","src":require("@/assets/schema.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"terms_privacy_1"}},[_c('p',[_vm._v("PRIVACY POLICY")]),_c('p',[_vm._v(" L’utente, acquisite le informazioni dal Titolare, presta il consenso al trattamento dei propri dati al fine di accedere al servizio richiesto. ")]),_c('p',[_vm._v("Informativa Privacy ex art. 13 Reg. (UE) 2016/679")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"terms_privacy_2"}},[_c('p',[_vm._v("COPERNICO MILANO SRL")]),_c('p',[_vm._v(" Società SRL Unipersonale - Capitale sociale 10.000 € i.v. - P.IVA e C.F.: 11508180962 – N. REA: MI 2608357 ")]),_c('p',[_vm._v(" Via dei Partigiani 109 - 20092 Cinisello Balsamo (MI) - Tel.: +39 02 24416763 ")]),_c('p',[_vm._v("e-mail:info@copernicomi.com - PEC: copernico.mi@legalmail.it")])])
}]

export { render, staticRenderFns }