<template>
  <div class="base-input" :class="classObj">
    <div v-if="inputType == 'simple'">
      <FormulateInput
        v-if="
          !inputSpecs.conditional ||
            (inputSpecs.conditional && checkCondition(inputSpecs.conditional))
        "
        v-bind="inputSpecs.props"
        label-has-errors-class="has-errors"
      />
    </div>
    <div v-if="inputType == 'multicolumn'" class="multicolumn">
      <div
        v-for="item in inputSpecs.items"
        :key="item.name"
        class="cluster-input"
        :class="item.flexible"
      >
        <FormulateInput
          v-if="
            !item.conditional ||
              (item.conditional && checkCondition(item.conditional))
          "
          v-bind="item.props"
          label-has-errors-class="has-errors"
        />
      </div>
    </div>
    <div v-if="inputType == 'cluster'">
      <FormulateInput
        v-if="!inputSpecs.conditional || checkCondition(inputSpecs.conditional)"
        #default="{ index }"
        type="group"
        v-bind="inputSpecs.cluster.props"
      >
        <div
          v-for="item in inputSpecs.clusterItems"
          :key="item.props.name"
          class="cluster-input"
          :class="item.flexible"
        >
          <FormulateInput
            v-bind="item.props"
            label-has-errors-class="has-errors"
            @blur="blurHandler"
          />
          <FormulateInput
            v-if="item.conditional && checkCondition(item.conditional, index)"
            v-bind="item.conditional.props"
            label-has-errors-class="has-errors"
          />
        </div>
      </FormulateInput>
    </div>
    <div v-if="inputType == 'map'">
      <div class="mapcomp">
        <BaseMap />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BaseInput",
  props: {
    inputType: {
      type: String,
      default: "simple",
      validator: function(value) {
        return (
          ["simple", "cluster", "multicolumn", "map"].indexOf(value) !== -1
        );
      }
    },
    inputSpecs: {
      type: Object,
      required: true
    },
    formValues: {
      type: Object,
      required: true
    }
  },
  computed: {
    classObj() {
      return {
        "margin--less": this.margin == "less",
        "margin--no": this.margin == "no"
      };
    },
    margin() {
      return this.inputSpecs.margin ? this.inputSpecs.margin : undefined;
    }
  },
  methods: {
    checkCondition(c, i) {
      let rtn = false;
      let val = this.formValues[c.keys[0]];
      switch (c.type) {
        case "==":
          c.values.forEach(value => {
            if (
              val == value ||
              (Array.isArray(val) && val[i] && val[i][c.keys[1]] == value)
            ) {
              rtn = true;
            }
          });
          break;
        case "!=":
          rtn = true;
          c.values.forEach(value => {
            if (val == value) {
              rtn = false;
            }
          });
          break;
        default:
          console.error("missing condition type for conditional input");
          break;
      }
      return rtn;
    },
    // special case for communication with map component
    blurHandler(data) {
      if (
        data.target.name == "latitudine" ||
        data.target.name == "longitudine"
      ) {
        let luogo = this.formValues.luogo;
        if (luogo[0].latitudine && luogo[0].longitudine) {
          this.$root.$emit("coordsfrominput", luogo);
        }
      }
    }
  }
};
</script>

<style lang="scss">
.base-input {
  &.margin--less {
    .formulate-input {
      margin-bottom: 0.75em;
    }
  }
  &.margin--no {
    .formulate-input {
      margin-bottom: 0em;
    }
  }
  .mapcomp {
    margin-bottom: 2em;
    height: 250px;
  }
}
.cluster-input {
  width: 100%;
}
.cluster-input.double {
  width: calc(50% - 0.5em);
}
.cluster-input.double:nth-of-type(odd) {
  margin-right: 1em;
}
.cluster-input.double--right:nth-of-type(even) {
  width: calc(30% - 0.5em);
}
.cluster-input.double--right:nth-of-type(odd) {
  width: calc(70% - 0.5em);
  margin-right: 1em;
}
.cluster-input.double--left:nth-of-type(even) {
  width: calc(70% - 0.5em);
}
.cluster-input.double--left:nth-of-type(odd) {
  width: calc(30% - 0.5em);
  margin-right: 1em;
}
.multicolumn,
.formulate-input.flexible .formulate-input-group-repeatable {
  display: flex;
  flex-wrap: wrap;
}
.formulate-input[data-classification="group"][data-type="group"] {
  &.no-margin {
    margin-bottom: 0em;
  }
}
</style>
