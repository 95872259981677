<template>
  <div id="home" class="page">
    <InterfaceServiceTerms :active="showTerms" @close="closingTerms" />
    <div class="header color dark">
      <div class="header-text-container">
        <div class="header-text" @click="showContacts">
          <h3>Contatta Copernico</h3>
        </div>
        <div class="header-text" @click="showServiceTerms">
          <h3>Termini di servizio</h3>
        </div>
      </div>
    </div>
    <div class="body">
      <div class="wrapper">
        <div class="buttons-container">
          <div class="buttons-wrapper">
            <BaseIcon
              name="back"
              :class="{ disabled: isFirstGroup }"
              @clicked="back"
            />
            <BaseIcon
              :class="{ disabled: !isFirstGroup }"
              name="logout"
              tip="logout"
              tip-left
              @clicked="logoutHandler"
            />
          </div>
        </div>
        <div class="info-container">
          <div class="info-slide">
            <transition :name="transitionType[0]">
              <div v-show="swiperIndex == 0" class="info-slide--content">
                <div class="title">
                  <h1>{{ companyName }}</h1>
                  <BaseIcon
                    name="modifica"
                    tip="modifica"
                    @clicked="editProfile"
                  />
                </div>
                <BaseButton
                  id="button-new"
                  label="Nuova segnalazione"
                  color="dark"
                  size="large"
                  @clicked="create"
                />
                <div class="info-slide--content-limited">
                  <p v-if="reportsIncomplete.length" class="section-title">
                    Da completare
                  </p>
                  <BaseReportLink
                    v-for="report in reportsIncomplete"
                    :key="report.report_uid"
                    :report="report"
                    @clicked="editReport(report)"
                    @cleared="removeReport(report)"
                  />
                  <p v-if="reportsComplete.length" class="section-title">
                    Completati
                  </p>
                  <BaseYearFolder
                    v-for="year in Object.keys(reportsCompleteByYear).sort(
                      sortYears
                    )"
                    :key="year"
                    :year="year"
                    :reports="reportsCompleteByYear[year]"
                    @edit="editReport"
                  />
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import InterfaceServiceTerms from "../components/InterfaceServiceTerms.vue";

export default {
  name: "ViewHome",

  components: { InterfaceServiceTerms },

  filters: {
    formatDate(timestamp) {
      let date = new Date(timestamp);
      return new Intl.DateTimeFormat("it-IT", {
        dateStyle: "full",
        timeStyle: "long"
      }).format(date);
    }
  },

  data() {
    return {
      companyKey: "ragione_sociale",
      swiperIndex: 0,
      transitionType: ["to-left", "to-right", "to-right"],
      showTerms: false
    };
  },

  computed: {
    ...mapGetters(["userData", "userReports"]),
    companyName() {
      return this.userData ? this.userData[this.companyKey] : undefined;
    },

    isFirstGroup() {
      return this.swiperIndex == 0;
    },

    reportsIncomplete() {
      return this.userReportsFilteredByStatus(["APD", "AP", "AD"]);
    },

    reportsComplete() {
      return this.userReportsFilteredByStatus("A");
    },

    reportsCompleteByYear() {
      let rtn = {};
      this.reportsComplete.forEach(report => {
        let date = new Date(report.data);
        let year = date.getFullYear();
        if (year in rtn) {
          rtn[year].push(report);
        } else {
          rtn[year] = [report];
        }
      });
      return rtn;
    }
  },

  mounted() {
    this.$root.$on("open-report", this.openReportHandler);
  },
  beforeDestroy() {
    this.$root.$off("open-report", this.openReportHandler);
  },

  methods: {
    ...mapActions(["logout", "deleteReport"]),
    create() {
      this.$router.push({
        name: "form",
        params: { new: true }
      });
    },

    editProfile() {
      this.$router.push({ name: "profile" });
    },

    editReport(report) {
      if (report)
        this.$router.push({
          name: "form",
          params: { new: false, report: report }
        });
    },

    logoutHandler() {
      this.$root.$emit("modal", {
        title: "Sicuro di voler uscire?",
        confirm: () => {
          this.logout();
          this.$router.push({ name: "login" });
        }
      });
    },
    back() {
      this.swiperIndex = 0;
    },
    next(index) {
      this.swiperIndex = index;
    },

    userReportsFilteredByStatus(status) {
      return this.userReports.filter(el => {
        let rtn = false;
        if (Array.isArray(status)) {
          if (status.includes(el.stato)) rtn = true;
        } else {
          if (status == el.stato) rtn = true;
        }
        return rtn;
      });
    },
    removeReport(report) {
      // console.log("rimuovi report", report.report_uid);
      this.$root.$emit("modal", {
        title: "Vuoi cancellare la segnalazione?",
        confirm: () => {
          this.deleteReport(report.report_uid);
        },
        undo: () => {},
        confirmLabel: "Sì, cancella",
        undoLabel: "No, non cancellare",
        colorConfirm: {
          color: "main"
        },
        colorUndo: {
          color: "main",
          transparent: true
        }
      });
    },
    showContacts() {
      this.$root.$emit("modal", {
        title: `<h1>Numero di emergenza</h1>
          <h1><a href="tel:0039800370036" style="color: white">+39 800370036</a></h1>
          <h1>Email</h1>
          <h1><a href="mailto:pia@copernicomi.com" style="color: white">pia@copernicomi.com</a></h1>
        `,
        // "Numero di emergenza\n00393473333333\n\nEmail\ncopernico@finta.it",
        customHtml: true,
        fullscreen: true,
        color: "dark",
        alignLeft: true
      });
    },
    showServiceTerms() {
      this.showTerms = true;
    },
    closingTerms() {
      this.showTerms = false;
    },
    openReportHandler(uid) {
      this.editReport(this.getReportByUid(uid));
    },
    getReportByUid(uid) {
      return this.userReports.find(el => el.report_uid == uid);
    },
    sortYears(a, b) {
      return b - a;
    }
  }
};
</script>

<style scoped lang="scss">
#home {
  .body {
    background-color: $color-light;
  }
  .header-text {
    cursor: pointer;
  }
  .info-slide--content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.5em;
    margin-top: 3em;
  }
  .title > div:last-child {
    margin-left: 1em;
  }

  #button-new {
    margin-bottom: 4rem;
  }

  .section-title {
    width: 100%;
    color: $color-gray;
    padding-left: 1.5rem;
  }
}
</style>
