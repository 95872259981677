<template>
  <FormulateInput
    :type="type"
    :label="label"
    :input-class="inputClasses"
    :class="{
      fillspace: fillspace,
      'size-small': size == 'small',
      'size-medium': size == 'medium',
      'size-large': size == 'large'
    }"
    @click="emitClick"
  />
</template>

<script>
export default {
  name: "Button",
  props: {
    type: {
      type: String,
      default: "button"
    },
    label: {
      type: String,
      required: true
    },
    // cb: {
    //   type: Function,
    //   default: function() {
    //     console.error("undefined callback");
    //   }
    // },
    color: {
      type: String,
      default: "main",
      validator: function(value) {
        return ["main", "dark", "error", "gray", "white"].indexOf(value) !== -1;
      }
    },
    fillspace: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "medium",
      validator: function(value) {
        return ["small", "medium", "large"].indexOf(value) !== -1;
      }
    },
    transparent: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClasses() {
      let rtn = [this.color];
      if (this.fillspace) rtn.push("fillspace");
      if (this.transparent) rtn.push("transparent");
      return rtn;
    }
  },
  methods: {
    emitClick() {
      this.$emit("clicked");
    }
  }
};
</script>

<style lang="scss">
.formulate-input[data-classification="button"] button {
  border-radius: $button-border-radius;
  min-width: $button-min-width;
  font-weight: normal;
  justify-content: center;
  line-height: 1.25em;
  &.dark {
    background-color: $color-black;
    border-color: $color-black;
  }
  &.error {
    background-color: $color-error;
    border-color: $color-error;
  }
  &.gray {
    background-color: $color-gray;
    border-color: $color-gray;
  }
  &.white {
    background-color: $color-white;
    border-color: $color-white;
    color: $color-black;
  }
  &.transparent {
    background-color: transparent;
    &.main {
      color: $color-ok;
    }
    &.white {
      color: $color-white;
    }
  }
  &.fillspace {
    width: 100%;
  }
}

.fillspace.formulate-input {
  width: 100%;
}

.formulate-input[data-classification="button"] {
  &.size-small button {
    font-size: 0.8em;
    padding-left: 1.3em;
    padding-right: 1.3em;
  }
  &.size-medium button {
    font-size: 1.1em;
    padding-left: 1.5em;
    padding-right: 1.5em;
  }
  &.size-large button {
    font-size: 1.5em;
    padding-left: 1.35em;
    padding-right: 1.35em;
  }
}
</style>
