import firebase from "firebase";
import "firebase/auth";
import "firebase/database";
import firebaseConfig from "./../../firebaseconfig.js";
import axios from "axios";

let urlFunctions = process.env.VUE_APP_USE_EMULATORS == 'true' ? `http://localhost:5001/${firebaseConfig.projectId}/us-central1` : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`;
// let actionCodeSettings = {
//   url: `https://${firebaseConfig.resetPassRedirectURL}`
// };
export default {
  init() {
    firebase.initializeApp(firebaseConfig);
    if(process.env.VUE_APP_USE_EMULATORS == 'true') {
      firebase.auth().useEmulator('http://localhost:9099/');
      firebase.functions().useEmulator("localhost", 5001);
      firebase.database().useEmulator("localhost", 9000);
      firebase.storage().useEmulator("localhost", 9199);
    }
  },

  login(email, password) {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  },

  logout() {
    return firebase.auth().signOut();
  },

  setOnAuthStateChangedHandler(cb) {
    firebase.auth().onAuthStateChanged(cb);
  },

  signup(email, password) {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  },

  registerUser(userInfo) {
    return firebase
      .database()
      .ref("users/" + firebase.auth().currentUser.uid)
      .set(userInfo);
  },

  getUserRef() {
    return firebase.database().ref("users/" + firebase.auth().currentUser.uid);
  },

  detachAllListeners() {
    firebase.database().off();
  },

  updateUserData(data) {
    return firebase
      .database()
      .ref("users/" + firebase.auth().currentUser.uid)
      .update(data);
  },

  postReport(data) {
    return firebase
      .database()
      .ref("reports")
      .push()
      .set(data);
  },

  deleteReport(uid) {
    return firebase
      .database()
      .ref("/reports")
      .orderByChild("report_uid")
      .equalTo(uid)
      .once("value", ref => {
        return new Promise(res => {
          let reports = ref.val();
          let issues = Object.keys(reports);
          issues.forEach(issue => {
            firebase
              .database()
              .ref("/reports/" + issue)
              .remove();
          });
          axios
            .post(urlFunctions + "/deleteReportImages", {
              reportUid: uid
            })
            .then(res => {
              return res.data;
            })
            .catch(err => {
              console.error("error on deleting images", err);
            });
          res();
        });
      });
  },

  signInWithCustomToken(token) {
    return firebase.auth().signInWithCustomToken(token);
  },

  getCurrentUser() {
    return firebase.auth().currentUser;
  },

  getUserReports(cb) {
    firebase
      .database()
      .ref("/reports")
      .orderByChild("user_uid")
      .equalTo(firebase.auth().currentUser.uid)
      .on("value", cb);
  },
  storeImages(ref, file) {
    return firebase
      .storage()
      .ref()
      .child(ref)
      .put(file);
  },
  storageRefToURL(ref) {
    return firebase
      .storage()
      .ref(ref)
      .getDownloadURL();
  },
  sendPasswordResetEmail(email) {
    return firebase
      .auth()
      .sendPasswordResetEmail(
        email,
        // firebaseConfig.resetPassRedirectURL ? actionCodeSettings : null
      );
  }
};
