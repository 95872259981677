let messages = {
  location: () => {
    return "Prego inserire almeno via, località o coordinate";
  },
  nonexistent_u: () => {
    return "Username già esistente";
  },
  nonexistent_e: () => {
    return "Email già registrata";
  }
};

export default {
  fields: [
    {
      name: "email",
      forms: ["signup"],
      groupId: 10,
      order: 0,
      props: {
        name: "email",
        type: "email",
        label: "Email",
        placeholder: "Email",
        validation: "required|nonexistent|email",
        validationMessages: {
          nonexistent: messages["nonexistent_e"]
        }
      }
    },
    {
      name: "password",
      forms: ["signup"],
      groupId: 10,
      order: 1,
      props: {
        name: "password",
        type: "password",
        label: "Password",
        placeholder: "Password",
        validation: "required|min:6,length"
      }
    },
    {
      name: "password_confirm",
      forms: ["signup"],
      groupId: 10,
      order: 2,
      props: {
        name: "password_confirm",
        type: "password",
        label: "Conferma Password",
        placeholder: "Password",
        validation: "required|confirm"
      }
    },
    {
      name: "username",
      forms: ["signup", "profile"],
      groupId: 10,
      order: 0,
      props: {
        name: "username",
        type: "text",
        label: "Nome Utente",
        placeholder: "Nome Utente",
        validation: "alphanumeric|required|nonexistent",
        validationMessages: {
          nonexistent: messages["nonexistent_u"]
        }
      }
    },
    {
      name: "ragione_sociale",
      forms: ["signup", "profile"],
      groupId: 11,
      order: 3,
      props: {
        name: "ragione_sociale",
        type: "text",
        label: "Ragione Sociale",
        placeholder: "Ragione Sociale",
        validation: "required"
      }
    },
    {
      name: "partita_iva",
      forms: ["signup", "profile"],
      groupId: 11,
      order: 4,
      props: {
        name: "partita_iva",
        type: "text",
        label: "Partita IVA",
        placeholder: "Partita IVA",
        validation: "number|required",
        inputmode: "numeric"
      }
    },
    {
      name: "indirizzo_sede_legale",
      forms: ["signup", "profile"],
      groupId: 11,
      order: 5,
      type: "cluster",
      cluster: {
        props: {
          repeatable: false,
          name: "indirizzo_sede_legale",
          label: "Indirizzo sede legale",
          class: "flexible",
          validation: "required"
        }
      },
      clusterItems: [
        {
          flexible: "double--right",
          props: {
            name: "via",
            type: "text",
            label: "Via/piazza",
            placeholder: "Via/piazza",
            validation: ""
          }
        },
        {
          flexible: "double--right",
          props: {
            name: "civico",
            type: "text",
            label: "Civico",
            placeholder: "Civico",
            validation: "number",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "cap",
            type: "text",
            label: "CAP",
            placeholder: "CAP",
            validation: "number|matches:/[0-9]{5}/|optional",
            inputmode: "numeric|optional"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "localita",
            type: "text",
            label: "Località",
            placeholder: "Località",
            validation: "required"
          }
        }
      ]
    },
    {
      name: "telefono",
      forms: ["signup", "profile"],
      groupId: 11,
      order: 9,
      props: {
        name: "telefono",
        type: "text",
        label: "Telefono",
        placeholder: "Telefono",
        validation: "number",
        inputmode: "numeric|required|min:9"
      }
    },
    {
      name: "codice_destinatario_sdi",
      forms: ["signup", "profile"],
      groupId: 12,
      order: 4,
      props: {
        name: "codice_destinatario_sdi",
        type: "text",
        label: "Codice destinatario SDI",
        placeholder: "Codice destinatario SDI",
        validation: "required"
      }
    },
    {
      name: "pec",
      forms: ["signup", "profile"],
      groupId: 12,
      order: 5,
      props: {
        name: "pec",
        type: "text",
        label: "PEC",
        placeholder: "PEC",
        validation: "email|required"
      }
    },
    {
      name: "compagnia_assicurativa",
      forms: ["signup", "profile"],
      groupId: 12,
      order: 6,
      props: {
        name: "compagnia_assicurativa",
        type: "text",
        label: "Compagnia Assicurativa",
        placeholder: "Compagnia Assicurativa",
        validation: "required"
      }
    },
    {
      name: "altre_sedi_operative",
      forms: ["signup", "profile"],
      groupId: 13,
      order: 3,
      type: "cluster",
      cluster: {
        props: {
          repeatable: true,
          name: "altre_sedi_operative",
          limit: 3,
          label: "Altre sedi operative",
          addLabel: "Aggiungi sede",
          class: "flexible"
        }
      },
      clusterItems: [
        {
          flexible: "double--right",
          props: {
            name: "via",
            type: "text",
            label: "Via/piazza",
            placeholder: "Via/piazza",
            validation: ""
          }
        },
        {
          flexible: "double--right",
          props: {
            name: "civico",
            type: "text",
            label: "Civico",
            placeholder: "Civico",
            validation: "number",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "cap",
            type: "text",
            label: "CAP",
            placeholder: "CAP",
            validation: "number|matches:/[0-9]{5}/|optional",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "localita",
            type: "text",
            label: "Località",
            placeholder: "Località",
            validation: ""
          }
        }
      ]
    },
    {
      name: "materiali_trasportati",
      forms: ["signup", "profile"],
      groupId: 13,
      order: 4,
      type: "cluster",
      cluster: {
        props: {
          repeatable: true,
          name: "materiali_trasportati",
          limit: 5,
          label: "Materiali Trasportati",
          addLabel: "Aggiungi materiale"
        }
      },
      clusterItems: [
        {
          props: {
            name: "materiale",
            type: "select",
            label: "",
            options: {
              idrocarburi: "Idrocarburi",
              rifiuti: "Rifiuti",
              altro: "altro..."
            },
            placeholder: "Materiale",
            validation: ""
          },
          conditional: {
            type: "==",
            keys: ["materiali_trasportati", "materiale"],
            values: ["altro"],
            props: {
              name: "materiale_altro",
              type: "text",
              label: "",
              placeholder: "Specificare materiale",
              validation: ""
            }
          }
        }
      ]
    },
    {
      name: "flotta",
      forms: ["signup", "profile"],
      groupId: 13,
      order: 5,
      props: {
        name: "flotta",
        type: "select",
        options: {
          uno: "1",
          dieci: "10",
          cento: "100",
          mille: "1000",
          maggiore_mille: ">1000"
        },
        label: "Flotta",
        placeholder: "Flotta",
        validation: ""
      }
    },
    {
      name: "referente_ambientale",
      forms: ["signup", "profile"],
      groupId: 13,
      order: 6,
      props: {
        name: "referente_ambientale",
        type: "text",
        label: "Referente ambientale",
        placeholder: "Referente ambientale",
        validation: ""
      }
    },
    {
      name: "referente_ambientale_telefono",
      forms: ["signup", "profile"],
      groupId: 13,
      order: 7,
      type: "multicolumn",
      multicolumnId: "refamb",
      flexible: "double",
      conditional: {
        type: "!=",
        keys: ["referente_ambientale"],
        values: [undefined, ""]
      },
      props: {
        name: "referente_ambientale_telefono",
        type: "text",
        label: "Telefono referente",
        placeholder: "Telefono referente",
        validation: ""
      }
    },
    {
      name: "referente_ambientale_email",
      forms: ["signup", "profile"],
      groupId: 13,
      type: "multicolumn",
      multicolumnId: "refamb",
      flexible: "double",
      order: 8,
      conditional: {
        type: "!=",
        keys: ["referente_ambientale"],
        values: [undefined, ""]
      },
      props: {
        name: "referente_ambientale_email",
        type: "text",
        label: "Email referente",
        placeholder: "Email referente",
        validation: ""
      }
    },
    {
      name: "responsabile_ambientale_email",
      forms: ["signup", "profile"],
      groupId: 10,
      order: 2,
      props: {
        name: "responsabile_ambientale_email",
        type: "email",
        label: "Email responsabile",
        placeholder: "Email responsabile",
        validation: "required|email"
      }
    },
    {
      name: "regioni",
      forms: ["signup", "profile"],
      groupId: 13,
      order: 9,
      type: "cluster",
      cluster: {
        props: {
          repeatable: true,
          name: "regioni",
          limit: 8,
          label: "Regioni/aree in cui si opera",
          addLabel: "Aggiungi regione/area"
        }
      },
      clusterItems: [
        {
          props: {
            name: "regione",
            type: "select",
            label: "",
            optionGroups: {
              Aree: {
                nord: "Nord Italia",
                centro: "Centro Italia",
                sud: "Sud Italia",
                isole: "Isole"
              },
              Regioni: {
                abruzzo: "Abruzzo",
                basilicata: "Basilicata",
                calabria: "Calabria",
                campania: "Campania",
                emilia_romagna: "Emilia Romagna",
                friuli_venezia_giulia: "Friuli-Venezia Giulia",
                lazio: "Lazio",
                liguria: "Liguria",
                lombardia: "Lombardia",
                marche: "Marche",
                molise: "Molise",
                piemonte: "Piemonte",
                puglia: "Puglia",
                sardegna: "Sardegna",
                sicilia: "Sicilia",
                toscana: "Toscana",
                trentino_alto_adige: "Trentino-Alto Adige",
                umbria: "Umbria",
                valle_d_aosta: "Valle d'Aosta",
                veneto: "Veneto"
              }
            }
          }
        }
      ]
    },
    /////////////////////////////////////////////////////////////////
    {
      name: "luogo",
      forms: ["attivazione", "preliminare"],
      groupId: 3,
      order: 2,
      type: "cluster",
      cluster: {
        props: {
          repeatable: false,
          name: "luogo",
          label: "Posizione",
          help: "Compilare almeno via, località o coordinate",
          class: "flexible no-margin",
          validation: "location",
          validationMessages: {
            location: messages["location"]
          }
        }
      },
      clusterItems: [
        {
          props: {
            name: "Sede di riferimento",
            type: "select",
            options: {first: 'località sede legale', second: 'località altre sedi operative'},
            label: "sede di riferimento",
            placeholder: "Seleziona un opzione",
            validation: "required"
          }
        },
        {
          props: {
            name: "via",
            type: "text",
            label: "Via/piazza",
            placeholder: "Via/piazza",
            validation: ""
          }
        },
        {
          props: {
            name: "civico",
            type: "text",
            label: "Civico/kmetrica",
            placeholder: "Civico/kmetrica",
            validation: "",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "cap",
            type: "text",
            label: "CAP",
            placeholder: "CAP",
            validation: "^number|^matches:/[0-9]{5}/|optional",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "localita",
            type: "text",
            label: "Località",
            placeholder: "Località",
            validation: ""
          }
        },
        {
          flexible: "double",
          props: {
            name: "latitudine",
            type: "text",
            label: "Latitudine",
            placeholder: "Latitudine",
            validation: "number",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double",
          props: {
            name: "longitudine",
            type: "text",
            label: "Longitudine",
            placeholder: "Longitudine",
            validation: "number",
            inputmode: "numeric"
          }
        }
      ]
    },
    {
      name: "mappa",
      forms: ["attivazione", "preliminare"],
      groupId: 3,
      order: 3,
      type: "map"
    },
    {
      name: "punto_di_consegna",
      forms: ["attivazione", "preliminare"],
      groupId: 3,
      order: 3,
      props: {
        name: "punto_di_consegna",
        type: "checkbox",
        label: "Punto di consegna",
        validation: ""
      }
    },
    {
      name: "punto_di_consegna_recapito",
      forms: ["attivazione", "preliminare"],
      groupId: 3,
      order: 5,
      conditional: {
        type: "==",
        keys: ["punto_di_consegna"],
        values: [true]
      },
      props: {
        name: "punto_di_consegna_recapito",
        type: "text",
        label: "Recapito telefonico punto di consegna",
        placeholder: "Recapito telefonico",
        validation: ""
      }
    },
    {
      name: "punto_di_consegna_nome",
      forms: ["attivazione", "preliminare"],
      groupId: 3,
      order: 4,
      conditional: {
        type: "==",
        keys: ["punto_di_consegna"],
        values: [true]
      },
      props: {
        name: "punto_di_consegna_nome",
        type: "text",
        label: "Nome azienda punto di consegna",
        placeholder: "Azienda",
        validation: ""
      }
    },
    {
      name: "punto_di_consegna_indirizzo",
      forms: ["attivazione", "preliminare"],
      groupId: 3,
      order: 6,
      conditional: {
        type: "==",
        keys: ["punto_di_consegna"],
        values: [true]
      },
      type: "cluster",
      cluster: {
        props: {
          repeatable: false,
          name: "punto_di_consegna_indirizzo",
          label: "Indirizzo punto di consegna",
          class: "flexible"
        }
      },
      clusterItems: [
        {
          flexible: "double--right",
          props: {
            name: "via",
            type: "text",
            label: "Via/piazza",
            placeholder: "Via/piazza",
            validation: ""
          }
        },
        {
          flexible: "double--right",
          props: {
            name: "civico",
            type: "text",
            label: "Civico",
            placeholder: "Civico",
            validation: "number",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "cap",
            type: "text",
            label: "CAP",
            placeholder: "CAP",
            validation: "number|matches:/[0-9]{5}/|optional",
            inputmode: "numeric"
          }
        },
        {
          flexible: "double--left",
          props: {
            name: "localita",
            type: "text",
            label: "Località",
            placeholder: "Località",
            validation: ""
          }
        }
      ]
    },
    {
      name: "nome_autista",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 0,
      required: ["attivazione"],
      props: {
        name: "nome_autista",
        type: "text",
        label: "Nome e cognome autista",
        placeholder: "Nome e cognome",
        validation: ""
      }
    },
    {
      name: "telefono_autista",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 1,
      props: {
        name: "telefono_autista",
        type: "text",
        label: "Telefono autista",
        placeholder: "Telefono",
        validation: "required|number|min:9",
        inputmode: "numeric"
      }
    },
    {
      name: "targa_trattore",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 2,
      required: ["attivazione"],
      props: {
        name: "targa_trattore",
        type: "text",
        label: "Targa trattore",
        placeholder: "Targa trattore",
        validation: "alphanumeric|optional|min:6"
      }
    },
    {
      name: "targa_semirimorchio",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 3,
      props: {
        name: "targa_semirimorchio",
        type: "text",
        label: "Targa semirimorchio",
        placeholder: "Targa semirimorchio",
        validation: "alphanumeric|optional|min:6"
      }
    },
    {
      name: "altri_mezzi_coinvolti",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 4,
      props: {
        name: "altri_mezzi_coinvolti",
        type: "checkbox",
        label: "Sono stati coinvolti altri mezzi?",
        validation: ""
      }
    },
    {
      name: "altri_mezzi_coinvolti_nome",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 5,
      conditional: {
        type: "==",
        keys: ["altri_mezzi_coinvolti"],
        values: [true]
      },
      props: {
        name: "altri_mezzi_coinvolti_nome",
        type: "text",
        label: "Nome soggetti terzi coinvolti",
        placeholder: "Nome soggetti terzi coinvolti",
        validation: ""
      }
    },
    {
      name: "altri_mezzi_coinvolti_targa",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 6,
      conditional: {
        type: "==",
        keys: ["altri_mezzi_coinvolti"],
        values: [true]
      },
      props: {
        name: "altri_mezzi_coinvolti_targa",
        type: "text",
        label: "Targa mezzo coinvolto",
        placeholder: "Targa mezzo coinvolto",
        validation: "alphanumeric|optional"
      }
    },
    {
      name: "autista_ferito",
      forms: ["attivazione"],
      groupId: 4,
      order: 7,
      props: {
        name: "autista_ferito",
        type: "radio",
        label: "L'autista è ferito?",
        options: {
          true: "Si",
          false: "No"
        },
        validation: ""
      }
    },
    {
      name: "terzi_feriti",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 8,
      props: {
        name: "terzi_feriti",
        type: "radio",
        label: "Ci sono soggetti terzi feriti?",
        options: {
          true: "Si",
          false: "No"
        },
        validation: ""
      }
    },
    {
      name: "subvettore",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 9,
      props: {
        name: "subvettore",
        type: "radio",
        label: "Subvettore",
        options: {
          true: "Si",
          false: "No"
        },
        validation: ""
      }
    },
    {
      name: "subvettore_name",
      forms: ["attivazione", "preliminare"],
      groupId: 4,
      order: 10,
      required: ["attivazione"],
      conditional: {
        type: "==",
        keys: ["subvettore"],
        values: ["true"]
      },
      props: {
        name: "subvettore_name",
        type: "text",
        label: "Nome subvettore",
        placeholder: "Nome subvettore",
        validation: ""
      }
    },
    {
      name: "materiale_trasportato",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 0,
      required: ["attivazione"],
      margin: "less",
      props: {
        name: "materiale_trasportato",
        type: "select",
        label: "Materiale trasportato",
        options: {
          gasolio: "Gasolio",
          olio_motore: "Olio motore",
          altro: "altro..."
        },
        placeholder: "Materiale",
        validation: ""
      }
    },
    {
      name: "materiale_trasportato_altro",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 1,
      required: ["attivazione"],
      margin: "less",
      conditional: {
        type: "==",
        keys: ["materiale_trasportato"],
        values: ["altro"]
      },
      props: {
        name: "materiale_trasportato_altro",
        type: "text",
        label: "",
        placeholder: "Specificare materiale",
        validation: ""
      }
    },
    {
      name: "materiale_trasportato_quantita",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 2,
      required: ["attivazione"],
      type: "multicolumn",
      multicolumnId: "mtrtq",
      flexible: "double",
      props: {
        name: "materiale_trasportato_quantita",
        type: "text",
        label: "Quantità",
        placeholder: "Quantità",
        validation: "number",
        inputmode: "numeric"
      }
    },
    {
      name: "materiale_trasportato_unita",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 3,
      required: ["attivazione"],
      type: "multicolumn",
      multicolumnId: "mtrtq",
      flexible: "double",
      props: {
        name: "materiale_trasportato_unita",
        type: "select",
        label: "Unità di misura",
        options: {
          chilogrammi: "chilogrammi",
          tonnellate: "tonnellate",
          litri: "litri"
        },
        placeholder: "Unità",
        validation: "",
        inputmode: ""
      }
    },
    {
      name: "materiale_perso",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 4,
      margin: "less",
      props: {
        name: "materiale_perso",
        type: "select",
        label: "Materiale perso",
        options: {
          gasolio: "Gasolio",
          olio_motore: "Olio motore",
          altro: "altro..."
        },
        placeholder: "Materiale",
        validation: "required"
      }
    },
    {
      name: "materiale_perso_altro",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 5,
      margin: "less",
      conditional: {
        type: "==",
        keys: ["materiale_perso"],
        values: ["altro"]
      },
      props: {
        name: "materiale_perso_altro",
        type: "text",
        label: "",
        placeholder: "Specificare materiale",
        validation: "required"
      }
    },
    {
      name: "materiale_perso_quantita",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 6,
      required: ["attivazione"],
      type: "multicolumn",
      multicolumnId: "mtrpq",
      flexible: "double",
      props: {
        name: "materiale_perso_quantita",
        type: "text",
        label: "Quantità stimata",
        placeholder: "Quantità",
        validation: "number",
        inputmode: "numeric"
      }
    },
    {
      name: "materiale_perso_unita",
      forms: ["attivazione", "preliminare"],
      groupId: 5,
      order: 7,
      required: ["attivazione"],
      type: "multicolumn",
      multicolumnId: "mtrpq",
      flexible: "double",
      props: {
        name: "materiale_perso_unita",
        type: "select",
        label: "Unità di misura",
        options: {
          chilogrammi: "chilogrammi",
          tonnellate: "tonnellate",
          litri: "litri"
        },
        placeholder: "Unità",
        validation: "",
        inputmode: ""
      }
    },
    {
      name: "primo_contenimento",
      forms: ["attivazione"],
      groupId: 5,
      order: 8,
      props: {
        name: "primo_contenimento",
        type: "radio",
        options: {
          true: "Si",
          false: "No"
        },
        label: "E' stato effettuato un primo contenimento?",
        validation: "required"
      }
    },
    {
      name: "primo_contenimento_materiale",
      forms: ["attivazione"],
      groupId: 5,
      order: 9,
      conditional: {
        type: "==",
        keys: ["primo_contenimento"],
        values: [true]
      },
      props: {
        name: "primo_contenimento_materiale",
        options: {
          materiale_assorbente: "Materiale assorbente",
          sabbia: "Sabbia",
          altro: "altro..."
        },
        type: "select",
        label: "Materiale utilizzato",
        placeholder: "Materiale",
        validation: "required"
      }
    },
    {
      name: "primo_contenimento_materiale_altro",
      forms: ["attivazione"],
      groupId: 5,
      order: 9,
      conditional: {
        type: "==",
        keys: ["primo_contenimento_materiale"],
        values: ["altro"]
      },
      props: {
        name: "primo_contenimento_materiale_altro",
        type: "text",
        placeholder: "Specificare materiale",
        validation: "required"
      }
    },
    {
      name: "data",
      forms: ["attivazione", "preliminare"],
      groupId: 6,
      order: 0,
      props: {
        name: "data",
        type: "date",
        label: "Data sinistro",
        placeholder: "Data sinistro",
        validation: "required"
      }
    },
    {
      name: "ora",
      forms: ["attivazione", "preliminare"],
      groupId: 6,
      order: 1,
      props: {
        name: "ora",
        type: "time",
        label: "Ora sinistro",
        placeholder: "Ora sinistro",
        validation: "required"
      }
    },
    {
      name: "descrizione_sinistro",
      forms: ["attivazione"],
      groupId: 6,
      order: 1,
      props: {
        name: "descrizione_sinistro",
        type: "textarea",
        label: "Descrizione del sinistro",
        placeholder: "Descrizione",
        validation: "required"
      }
    },
    {
      name: "superfici_coinvolte",
      forms: ["attivazione"],
      groupId: 6,
      order: 2,
      props: {
        name: "superfici_coinvolte",
        options: {
          asfalto: "Asfalto",
          cemento: "Cemento",
          autobloccanti_pietra: "Pavimentazione autobloccanti-pietra",
          terreno: "Terreno",
          canali_torrenti_fiumi: "Canali, torrenti, fiumi",
          altro: "altro..."
        },
        type: "select",
        label: "Superfici coinvolte",
        placeholder: "Superfici coinvolte",
        validation: "required"
      }
    },
    {
      name: "superfici_coinvolte_altro",
      forms: ["attivazione"],
      groupId: 6,
      order: 3,
      conditional: {
        type: "==",
        keys: ["superfici_coinvolte"],
        values: ["altro"]
      },
      props: {
        name: "superfici_coinvolte_altro",
        type: "text",
        placeholder: "Specificare superfici",
        validation: "required"
      }
    },
    {
      name: "soggetti_intervenuti",
      forms: ["attivazione"],
      groupId: 6,
      order: 4,
      props: {
        name: "soggetti_intervenuti",
        options: {
          viglili_del_fuoco: "Vigili del fuoco",
          polizia_municipale_carabinieri: "Polizia - municipale - carabinieri",
          ambulanza: "Ambulanza",
          arpa: "ARPA",
          asl: "ASL",
          nessuno: "Nessuno",
          altro: "altro..."
        },
        type: "select",
        label: "Soggetti intervenuti",
        placeholder: "Soggetti intervenuti",
        validation: "required"
      }
    },
    {
      name: "soggetti_intervenuti_altro",
      forms: ["attivazione"],
      groupId: 6,
      order: 5,
      conditional: {
        type: "==",
        keys: ["soggetti_intervenuti"],
        values: ["altro"]
      },
      props: {
        name: "soggetti_intervenuti_altro",
        type: "text",
        placeholder: "Specificare soggetti",
        validation: "required"
      }
    },
    {
      name: "pictures",
      forms: ["attivazione", "preliminare"],
      groupId: 6,
      required: ["attivazione"],
      order: 6,
      props: {
        name: "pictures",
        type: "image",
        label: "Foto",
        placeholder: "Foto",
        validation: "",
        multiple: "true",
        uploadBehavior: "delayed"
      }
    }
    // {
    //   name: "pictures",
    //   forms: ["attivazione"],
    //   groupId: 2,
    //   order: 2,
    //   type: "cluster",
    //   cluster: {
    //     props: {
    //       repeatable: true,
    //       name: "pictures",
    //       limit: 8,
    //       label: "Foto",
    //       addLabel: "Aggiungi foto"
    //     }
    //   },
    //   clusterItems: [
    //     {
    //       props: {
    //         name: "picture",
    //         type: "image",
    //         label: "Foto",
    //         placeholder: "Foto",
    //         validation: "",
    //         multiple: "true"
    //       }
    //     }
    //   ]
    // },
  ],
  groups: [
    {
      id: 2,
      name: "Generali",
      order: 0
    },
    {
      id: 3,
      name: "Luogo",
      order: 1
    },
    {
      id: 4,
      name: "Soggetto incidentato",
      order: 2
    },
    {
      id: 5,
      name: "Materiale trasportato",
      order: 3
    },
    {
      id: 6,
      name: "Dinamica del sinistro",
      order: 4
    },
    {
      id: 10,
      name: "Account",
      order: 0
    },
    {
      id: 11,
      name: "Anagrafica",
      order: 1
    },
    {
      id: 12,
      name: "Contabile",
      order: 2
    },
    {
      id: 13,
      name: "Logistica",
      order: 3
    }
  ]
};
