<template>
  <!--   <transition name="fade"> -->
  <div v-if="active" id="modal" class="page">
    <div id="modal-box" :class="classObj">
      <div class="close">
        <BaseIcon
          name="close_thin"
          :color="colorInverted ? 'white' : 'dark'"
          :fill="true"
          @clicked="close"
        />
      </div>
      <div class="wrapper">
        <div class="content">
          <pre>
              <div v-if="customHtml" v-html="title"></div>
              <h1 v-if="fullscreen && !customHtml" class="text" :class="{centered: !alignLeft}">{{ title }}</h1>
              <p v-if="!fullscreen && !customHtml" class="text" :class="{centered: !alignLeft}">{{ title }}</p>
            </pre>
          <div class="buttons">
            <BaseButton
              v-if="cbConfirm"
              :label="labelConfirm"
              :color="colorConfirm.color"
              :transparent="colorConfirm.transparent"
              size="small"
              @clicked="confirm"
            />
            <BaseButton
              v-if="cbUndo"
              :label="labelUndo"
              :color="colorUndo.color"
              :transparent="colorUndo.transparent"
              size="small"
              @clicked="undo"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!--   </transition> -->
</template>

<script>
export default {
  name: "Modal",

  data() {
    return {
      active: false,
      title: undefined,
      cbConfirm: undefined,
      cbUndo: undefined,
      browserConfirm: undefined,
      labelConfirm: undefined,
      labelUndo: undefined,
      cbClose: undefined,
      fullscreen: undefined,
      color: undefined,
      colorConfirm: undefined,
      colorUndo: undefined,
      alignLeft: undefined,
      customHtml: undefined
    };
  },

  computed: {
    classObj() {
      return {
        box: !this.fullscreen,
        iverted: this.colorInverted,
        "color-error": this.color == "error",
        "color-main": this.color == "main",
        "color-dark": this.color == "dark"
      };
    },
    colorInverted() {
      return (
        this.color == "error" || this.color == "dark" || this.color == "main"
      );
    }
  },

  mounted() {
    // console.log("modal mounted");
    this.init();
    this.$root.$on("modal", this.modalEventHandler);
  },

  methods: {
    modalEventHandler(data) {
      if (this.browserConfirm) {
        if (data.title && data.confirm) {
          if (window.confirm(data.title)) {
            data.confirm();
          }
        }
      } else {
        this.color = data.color ? data.color : this.color;
        this.fullscreen = data.fullscreen ? data.fullscreen : this.fullscreen;
        this.title = data.title ? data.title : this.title;
        this.cbClose = data.close ? data.close : this.cbClose;
        this.cbConfirm = data.confirm ? data.confirm : this.cbConfirm;
        this.cbUndo = data.undo ? data.undo : this.cbUndo;
        this.colorConfirm = data.colorConfirm
          ? data.colorConfirm
          : this.colorConfirm;
        this.colorUndo = data.colorUndo ? data.colorUndo : this.colorUndo;
        this.labelConfirm = data.confirmLabel
          ? data.confirmLabel
          : this.labelConfirm;
        this.labelUndo = data.undoLabel ? data.undoLabel : this.labelUndo;
        this.alignLeft = data.alignLeft ? data.alignLeft : this.alignLeft;
        this.customHtml = data.customHtml ? data.customHtml : this.customHtml;
        this.active = true;
      }
    },
    doNothing() {
      this.destroy();
    },
    undo() {
      if (this.cbUndo) this.cbUndo();
      this.destroy();
    },
    confirm() {
      if (this.cbConfirm) this.cbConfirm();
      this.destroy();
    },
    close() {
      if (this.cbClose) this.cbClose();
      this.destroy();
    },
    init() {
      this.color = "gray";
      this.fullscreen = false;
      this.title = "";
      this.cbClose = this.doNothing;
      this.cbConfirm = undefined;
      this.cbUndo = undefined;
      this.labelConfirm = "Ok";
      this.labelUndo = "Annulla";
      this.colorConfirm = {
        color: "main",
        transparent: false
      };
      this.colorUndo = {
        color: "dark",
        transparent: false
      };
      this.alignLeft = false;
      this.customHtml = false;
    },
    destroy() {
      this.active = false;
      this.init();
    }
  }
};
</script>

<style scoped lang="scss">
#modal {
  position: fixed;
  height: 100%;
  width: 100%;
  background: $color-background-overlay;
  z-index: $modal-z-index;
  align-items: center;
  /* experimental features must be enabled in chrome for the backdrop filter to work */
  backdrop-filter: $background-blur-main;
}
#modal-box {
  height: 100%;
  width: 100%;
  background: $color-white;
  padding: 1rem;
  box-sizing: border-box;

  .close {
    display: flex;
    justify-content: flex-end;
  }
  .wrapper {
    height: 65%;
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .content {
    width: 100%;
    max-width: calc(#{$max-width}* 0.7);
  }
  .text {
    margin-top: 0;
    margin-bottom: 3.5rem;

    &.centered {
      text-align: center;
    }
  }
  .buttons {
    display: flex;
    justify-content: space-around;
  }
}
#modal-box.color-error {
  background-color: $color-error;
  color: $color-white;
}
#modal-box.color-main {
  background-color: $color-ok;
  color: $color-white;
}
#modal-box.color-dark {
  background-color: $color-black;
  color: $color-white;
}
#modal-box.box {
  margin-top: $modal-margin-top;
  height: auto;
  width: calc(#{$max-width});
  filter: drop-shadow(0 0 0.7rem #{$color-shadow});

  .wrapper {
    margin-top: 1.5rem;
  }
  .text {
    margin-bottom: 1.5rem;
  }

  > div:last-child {
    margin-bottom: 1rem;
  }
}
// #modal {
//   position: fixed;
//   top: 0px;
//   width: 100vw;
//   height: 100vh;
//   z-index: 100;
//   display: flex;
//   flex-direction: column;
//   // justify-content: center;
//   align-items: center;
//   background: $color-background-overlay;
//   /* experimental features must be enabled in chrome for the backdrop filter to work */
//   backdrop-filter: $background-blur-main;
// }
// #modal-box {
//   margin-top: $modal-margin-top;
//   background-color: $color-white;
//   width: $max-width;
//   max-height: $max-width;
//   border-radius: 0.5em;
//   padding: $padding;
//   border-bottom: groove;
// }
// .buttons {
//   margin-top: $padding;
//   display: flex;
//   justify-content: space-between;
// }
// div.formulate-input {
//   margin: 0;
// }
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.2s;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
// }
</style>
