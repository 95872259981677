<template>
  <InterfaceForm
    :form-name="formName"
    :title="title"
    :submit-button="submitButton"
    @submit="submitHandler"
    @close="closeHandler"
  />
</template>

<script>
import { mapActions } from "vuex";
import InterfaceForm from "@/components/InterfaceForm.vue";

export default {
  name: "ViewProfile",

  components: { InterfaceForm },

  data() {
    return {
      formName: "profile",
      title: "Modifica Profilo",
      submitButton: {
        label: "Modifica"
      }
    };
  },

  methods: {
    ...mapActions(["updateUserData"]),

    submitHandler(data) {
      // console.log("submit: ", data);
      this.$root.$emit("modal", {
        title: "Aggiornare i dati del profilo?",
        confirm: () => {
          this.updateUserData(data).then(() => {
            this.$router.push({ name: "home" });
          });
        }
      });
    },
    closeHandler() {
      this.$root.$emit("modal", {
        title: "Uscire senza aggiornare i dati?",
        confirm: () => {
          this.$router.push({ name: "home" });
        },
        confirmLabel: "Esci"
      });
    }
  }
};
</script>

<style scoped></style>
