<template>
  <l-map
    ref="basemap"
    :options="mapOptions"
    :bounds="bounds"
    @click="clickHandler"
  >
    <l-tile-layer :url="url" />
    <l-marker
      :visible="showMarker"
      :lat-lng="markerLatLng"
      :draggable="true"
      :icon="icon"
      @dragend="dragendHandler"
    ></l-marker>
    <l-control position="topright">
      <div class="map-buttons-container">
        <div class="map-button" @click="locateHanlder">
          <BaseIcon
            color="dark"
            name="location_1"
            style="pointer-events: none"
          />
        </div>
        <div class="map-button" @click="clear">
          <BaseIcon
            color="dark"
            fill
            name="clear"
            style="pointer-events: none"
          />
        </div>

        <!-- <div class="map-button" @click="fullscreenHandler"></div> -->
      </div>
      <!-- <BaseButton label="locate" @clicked="locateHanlder" color="dark"/> -->
    </l-control>
    <l-control-fullscreen
      :options="{ false: 'Go big!', true: 'Be regular' }"
      position="topright"
    />
  </l-map>
</template>

<script>
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControl } from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import "leaflet/dist/leaflet.css";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    // LCircleMarker,
    LMarker,
    LControl,
    LControlFullscreen
    // LIcon
  },
  data() {
    return {
      mapOptions: {
        maxBounds: [
          [36.619987291, 6.7499552751],
          [47.1153931748, 18.4802470232]
        ]
      },
      bounds: [
        [36.619987291, 6.7499552751],
        [47.1153931748, 18.4802470232]
      ],
      markerLatLng: [40.619987291, 15.7499552751],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      icon: L.icon({
        iconUrl: require(`@/assets/location_2.png`),
        iconSize: [36, 36],
        iconAnchor: [18, 36]
      }),
      marker: null,
      showMarker: false,
      init: false
    };
  },
  computed: {
    map() {
      return this.$refs.basemap ? this.$refs.basemap.mapObject : undefined;
    }
  },
  mounted() {
    this.$root.$on("prefilled", this.prefilledHandler);
    this.$root.$on("coordsfrominput", this.setCoordinatesFromInput);
  },
  beforeDestroy() {
    this.$root.$off("prefilled", this.prefilledHandler);
    this.$root.$off("coordsfrominput", this.setCoordinatesFromInput);
  },
  methods: {
    dragendHandler(coords) {
      let coord = coords.target._latlng;
      this.setMarkerCoord([coord.lat, coord.lng]);
    },
    clickHandler(d) {
      if (!this.showMarker) {
        this.setMarkerCoord([d.latlng.lat, d.latlng.lng]);
        this.showMarker = true;
      }
    },
    setMarkerCoord(coords) {
      this.markerLatLng = coords;
      this.$root.$emit("located", coords);
    },
    getLocation() {
      if (!navigator.geolocation) {
        console.error("geolocation is not supported by your browser");
      } else {
        navigator.geolocation.getCurrentPosition(
          this.onLocalizationSuccess,
          this.onLocalizationError
        );
      }
    },
    onLocalizationSuccess(position) {
      // console.log("position", position);
      this.showMarker = true;
      let lat = position.coords.latitude;
      let lon = position.coords.longitude;
      let coords = [lat, lon];
      this.setMarkerCoord(coords);
      this.map.flyTo(coords, 14, { duration: 0.3 });

      // if (!this.formValues["luogo"]) {
      //   this.$set(this.formValues, "luogo", []);
      // }
      // if (!this.formValues["luogo"].length) {
      //   let obj = { latitudine: lat, longitudine: lon };
      //   this.formValues["luogo"].push(obj);
      // } else {
      //   this.$set(this.formValues["luogo"][0], "latitudine", lat);
      //   this.$set(this.formValues["luogo"][0], "longitudine", lon);
      // }
    },
    onLocalizationError() {
      if (this.map.isFullscreen()) this.map.toggleFullscreen();
      this.$root.$emit("modal", {
        title: `L'accesso alla posizione è stato precedentemente negato per questo sito.\n\nPer il rilevamento automatico della posizione si prega di abilitare il permesso dalle impostazioni del browser.
        `
      });
      console.error("unable to retrieve your location");
    },
    locateHanlder() {
      this.getLocation();
    },
    clear() {
      this.showMarker = false;
      this.map.zoomOut(2);
      this.$root.$emit("cleared");
    },
    prefilledHandler(obj) {
      if (!this.init) {
        this.init = true;
        if (!obj.new) {
          if (
            obj.luogo &&
            obj.luogo[0].latitudine &&
            obj.luogo[0].longitudine
          ) {
            let position = {
              coords: {
                latitude: obj.luogo[0].latitudine,
                longitude: obj.luogo[0].longitudine
              }
            };
            this.onLocalizationSuccess(position);
          }
        } else {
          this.getLocation();
        }
      }
    },
    setCoordinatesFromInput(luogo) {
      if (luogo && luogo[0].latitudine && luogo[0].longitudine) {
        let position = {
          coords: {
            latitude: luogo[0].latitudine,
            longitude: luogo[0].longitudine
          }
        };
        this.onLocalizationSuccess(position);
      }
    }
  }
};
</script>

<style lang="scss">
.map-buttons-container {
  $size: 2.6em;
  display: flex;
  justify-content: space-between;
  width: calc(#{$size}* 2);

  .map-button {
    background-color: $color-white;
    width: $size;
    height: $size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-style: solid;
    border-width: 1px;
    border-color: $color-gray;
    filter: drop-shadow(0 0 0.1rem #{$color-shadow});

    &:first-child {
      border-radius: 0.3em 0 0 0.3em;
    }
    &:last-child {
      border-radius: 0 0.3em 0.3em 0;
    }
    &:hover {
      background-color: $color-light;
    }
  }
}

.leaflet-control-container {
  .leaflet-top.leaflet-right {
    display: flex;
  }
}
</style>
