<template>
  <div class="link-container" :class="classObj" @click="clickHandler">
    <p>{{ label }}</p>
    <BaseIcon
      v-if="isDraft"
      name="clear"
      color="white"
      :fill="true"
      @clicked="clearHandler"
    />
  </div>
</template>

<script>
export default {
  name: "ReportLink",
  props: {
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    classObj() {
      return {
        "color-error": this.isDraft,
        "color-main": this.isPreliminary
      };
    },
    isDraft() {
      return this.report.stato == "APD";
    },
    isPreliminary() {
      return this.report.stato == "AP" || this.report.stato == "AD";
    },
    isCompleted() {
      return !this.isDraft && !this.isPreliminary;
    },
    label() {
      let rtn = "";
      if (
        this.report.luogo &&
        this.report.luogo.length &&
        this.report.luogo[0].localita
      )
        rtn = this.report.luogo[0].localita + ", ";
      if (this.report.data) rtn = rtn + this.report.data.replaceAll("-", "/");
      if (this.isDraft) rtn = "Draft - " + rtn;
      return rtn;
    }
  },
  methods: {
    clickHandler() {
      this.$emit("clicked");
    },
    clearHandler() {
      this.$emit("cleared");
    }
  }
};
</script>

<style lang="scss">
.link-container {
  cursor: pointer;
  height: 2.5rem;
  background-color: $color-white;
  color: $color-black;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  box-sizing: border-box;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &.color-error {
    color: $color-white;
    background-color: $color-error;
  }
  &.color-main {
    color: $color-white;
    background-color: $color-ok;
  }
}
</style>
