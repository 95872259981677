<template>
  <!--   <transition name="fade"> -->
  <div v-if="active" id="service_terms_cont" class="page terms">
    <a id="home_link" :href="homeLink"
      ><img id="terms_logo" src="@/assets/logo_mi.png"
    /></a>
    <div id="bonifica_cont">
      <img id="bonifica" src="@/assets/box-bonifica.jpg" />
    </div>
    <div class="intro_style">
      <b>Copernico Milano s.r.l.</b> è una struttura nata dall’esperienza di
      professionisti e imprese da anni impegnati in campo ambientale. Ci
      occupiamo di attività di progettazione, consulenza, coordinamento e
      direzione lavori in tutti gli ambiti della tutela ambientale a favore di
      privati, aziende ed Enti pubblici. Offriamo un servizio di PIA (Pronto
      Intervento Ambientale) per la messa in sicurezza d’emergenza e la bonifica
      delle aree coinvolte da sversamenti di sostanze pericolose (a seguito di
      incidenti stradali o nei cicli produttivi industriali).
    </div>
    <div class="style_box">
      <div class="style_box_cont">
        <span class="style_title">PRONTO INTERVENTO AMBIENTALE</span>
        <div class="style_body">
          Dopo aver contrattualizzato il servizio, esso è attivo 24h/24h su
          tutto il territorio nazionale con tempestiva attivazione di una
          squadra PIA
        </div>
      </div>
      <div class="style_box_cont">
        <span class="style_title">COME SI ATTIVA</span>
        <div class="style_body">
          Tramite segnalazione diretta su webapp dedicata, oppure tramite call
          telefonica ed invio del verbale di segnalazione per email
        </div>
      </div>
      <div class="style_box_cont">
        <span class="style_title">QUANDO SI ATTIVA</span>
        <div class="style_body">
          A seguito di sversamento di prodotti potenzialmente contaminanti,
          compresi carburanti e oli idraulici
        </div>
      </div>
      <div class="style_box_cont">
        <span class="style_title">COME E DOVE OPERIAMO</span>
        <div class="style_body">
          Le squadre PIA sono distribuite capillarmente sul territorio e sono
          dotate delle autorizzazioni necessarie ad operare nei differenti
          ambiti. Una rete nazionale di professionisti garantisce il
          coordinamento diretto delle attività di cantiere.
        </div>
      </div>
    </div>
    <div>
      <img id="terms_schema" src="@/assets/schema.png" />
    </div>
    <div class="close">
      <BaseIcon
        name="close_thin"
        :color="'dark'"
        :fill="true"
        @clicked="close"
      />
    </div>
    <div id="terms_privacy_1">
      <p>PRIVACY POLICY</p>
      <p>
        L’utente, acquisite le informazioni dal Titolare, presta il consenso al
        trattamento dei propri dati al fine di accedere al servizio richiesto.
      </p>
      <p>Informativa Privacy ex art. 13 Reg. (UE) 2016/679</p>
    </div>
    <div id="terms_privacy_2">
      <p>COPERNICO MILANO SRL</p>
      <p>
        Società SRL Unipersonale - Capitale sociale 10.000 € i.v. - P.IVA e
        C.F.: 11508180962 – N. REA: MI 2608357
      </p>
      <p>
        Via dei Partigiani 109 - 20092 Cinisello Balsamo (MI) - Tel.: +39 02
        24416763
      </p>
      <p>e-mail:info@copernicomi.com - PEC: copernico.mi@legalmail.it</p>
    </div>
  </div>
  <!--   </transition> -->
</template>

<script>
export default {
  name: "InterfaceServiceTerms",

  props: {
    active: {
      type: Boolean,
      default: false,
      homeLink: location.origin
    }
  },

  data() {
    return {
      body: ""
    };
  },

  computed: {
    homeLink() {
      return location.origin;
    }
  },

  mounted() {},

  methods: {
    close() {
      this.$emit("close");
    }
  }
};
</script>

<style scoped lang="scss">
// #modal {
//   position: fixed;
//   top: 0px;
//   width: 100vw;
//   height: 100vh;
//   z-index: 100;
//   display: flex;
//   flex-direction: column;
//   // justify-<div>enter;
//   alig</div>ter;
//   background: $color-background-overlay;
//   /* experimental features must be enabled in chrome for the backdrop filter to work */
//   backdrop-filter: $background-blur-main;
// }
// #modal-box {
//   margin-top: $modal-margin-top;
//   background-color: $color-white;
//   width: $max-width;
//   max-height: $max-width;
//   border-radius: 0.5em;
//   padding: $padding;
//   border-bottom: groove;
// }
// .buttons {
//   margin-top: $padding;
//   display: flex;
//   justify-<div>pace-betwe</div>
// div.formulate-input {
//   margin: 0;
// }
// .fade-enter-active,
// .fade-leave-active {
//   transition: opacity 0.2s;
// }
// .fade-enter,
// .fade-leave-to {
//   opacity: 0;
// }
#service_terms_cont {
  position: absolute;
  left: 0;
  top: 0;
  width: auto;
  height: auto;
  z-index: 1;
  background-color: white;
  text-align: center;
  padding: 1rem;
  color: grey;
  .close {
    padding: 1rem;
    position: fixed;
    right: 0;
    top: 0;
    display: flex;
    justify-content: flex-end;
  }
}
.intro_style {
  margin: 10px 0;
  text-align: left;
}
.style_box_cont {
  width: 100%;
  text-align: left;
  border: 0.5px solid black;
  padding: 10px;
  margin: 5px 0;
}
.style_box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // margin: 20px;
}
.style_title {
  background-color: grey;
  color: white;
  font-weight: bold;
  padding: 2px;
}
.style_body {
  font-size: 15px;
  margin-top: 5px;
}
#terms_logo {
  width: 130px;
  height: auto;
  align-self: center;
}
#bonifica_cont {
  width: 100%;
  overflow: hidden;
  height: 50vw;
}
#bonifica {
  width: 100%;
  height: auto;
  margin-top: 50%;
  transform: translateY(-80%);
}
#terms_schema {
  width: 80%;
}
@media only screen and (max-width: 920px) {
  #terms_schema {
    width: 100%;
  }
}
.page.terms {
  background-color: white;
}
#terms_privacy_2 {
  background-color: grey;
  p {
    margin: 5px 0;
    font-size: 12px;
    color: white;
  }
}
#terms_privacy_1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  p {
    margin: 5px 0;
    font-size: 12px;
    color: grey;
  }
}
#home_link {
  cursor: pointer;
}
</style>
