import Vue from "vue";
import App from "./App.vue";
import router from "./js/router.js";
import store from "./js/store.js";
import fb from "./js/lib_firebase.js";
import VueFormulate from "@braid/vue-formulate";
import { it } from "@braid/vue-formulate-i18n";
import firebaseConfig from "../firebaseconfig.js";
import axios from "axios";

let functionsUrl = process.env.VUE_APP_USE_EMULATORS == 'true' ? `http://localhost:5001/${firebaseConfig.projectId}/us-central1` : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`
//utility
let notEmpty = function(val) {
  return val !== undefined && val != "";
};

Vue.config.productionTip = false;

// Vue.use(VueFormulate);
Vue.use(VueFormulate, {
  plugins: [it],
  locale: "it",
  locales: {
    it: {
      required() {
        return `Campo obbligatorio.`;
      }
    }
  },
  slotComponents: {
    label: "BaseSlotLabel"
  },
  uploader: function(file) {
    return new Promise((res, rej) => {
      // const formData = new FormData();
      // formData.append('file', file);
      store.commit("setLoading", true);
      let pathImage =
        "images/" + store.state.currentReportCode + "/" + file.name;
      fb.storeImages(pathImage, file)
        .then(() => {
          res([
            {
              url: pathImage,
              name: file.name
            }
          ]);

          // fb.storageRefToURL("provaimmagini/" + file.name).then(response => {
          //   let rtn = [{ url: response }];
          //   console.log("succesfull stored image", rtn);
          //   res(rtn);
          // });

          // res(uploaded);
        })
        .catch(err => {
          console.log("error on storing image", err);
          rej(err);
        });
    });
  },
  rules: {
    nonexistent: ({ value }) => {
      return new Promise(resolve => {
        axios
          .post(`${functionsUrl}/usernameExists`, { username: value })
          .then(res => {
            let data = res.data;
            if (
              data.result &&
              store.getters.user &&
              data.email != store.getters.user.email
            ) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(err => {
            console.error("error while checking username/email existence", err);
          });
      });
    },
    location: function({ value }) {
      let rtn = false;
      if (Array.isArray(value)) {
        const [location] = value;
        rtn =
          notEmpty(location.via) ||
          notEmpty(location.localita) ||
          (notEmpty(location.latitudine) && notEmpty(location.longitudine));
      }
      return rtn;
    }
  }
});

// initialize firebase
fb.init();

// globally import Base components
const requireComponent = require.context(
  // Look for files in the current directory
  "./components",
  // Do not look in subdirectories
  false,
  // Only include "_base-" prefixed .vue files
  /Base[A-Z]\w+\.(vue)$/
);
requireComponent.keys().forEach(fileName => {
  // Get the component config
  const componentConfig = requireComponent(fileName);
  // Remove the file extension from the end
  const componentName = fileName.replace(/^\.\//, "").replace(/\.\w+$/, "");
  // Globally register the component
  Vue.component(componentName, componentConfig.default || componentConfig);
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
