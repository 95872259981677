<template>
  <div class="icon clickable tooltip nonselectable" @click.stop="clickHandler">
    <div v-if="isTip" class="tooltiptext" :class="{ left: tipLeft }">
      {{ tip }}
    </div>
    <div
      :class="[colorClass, { fill: fill }, { 'stroke-path': doStrokePath }]"
      v-html="icon"
    ></div>
  </div>
</template>

<script>
export default {
  name: "Icon",
  props: {
    name: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: "dark",
      validator: function(value) {
        return ["main", "dark", "error", "gray", "white"].indexOf(value) !== -1;
      }
    },
    fill: {
      type: Boolean,
      default: false
    },
    tip: {
      type: String,
      default: ""
    },
    tipLeft: {
      type: Boolean,
      default: false
    }
    // cb: {
    //   type: Function,
    //   default: function() {
    //     return;
    //   }
    // }
  },
  data() {
    return {
      icon: require(`!!html-loader!@/assets/${this.name}.svg`),
      colorMap: {
        main: "icon-color-main",
        dark: "icon-color-dark",
        error: "icon-color-error",
        gray: "icon-color-gray",
        white: "icon-color-white"
      }
    };
  },
  computed: {
    colorClass() {
      return this.colorMap[this.color];
    },
    doStrokePath() {
      return (
        this.name == "go_to" ||
        this.name == "next" ||
        this.name == "back" ||
        this.name == "drop_down"
      );
    },
    isTip() {
      return this.tip.length;
    }
  },
  methods: {
    clickHandler() {
      this.$emit("clicked");
    }
  }
};
</script>

<style lang="scss">
.icon {
  cursor: pointer;

  &.clickable {
    min-width: 2rem;
    min-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 0px;
  }
  .icon-color-main line {
    stroke: $color-ok;
  }
  .icon-color-dark line {
    stroke: $color-black;
  }
  .icon-color-error line {
    stroke: $color-error;
  }
  .icon-color-gray line {
    stroke: $color-gray;
  }
  .icon-color-white line {
    stroke: $color-white;
  }

  .icon-color-main.stroke-path path {
    stroke: $color-ok;
  }
  .icon-color-dark.stroke-path path {
    stroke: $color-black;
  }
  .icon-color-error.stroke-path path {
    stroke: $color-error;
  }
  .icon-color-gray.stroke-path path {
    stroke: $color-gray;
  }
  .icon-color-white.stroke-path path {
    stroke: $color-white;
  }

  .icon-color-main.fill path {
    fill: $color-ok;
  }
  .icon-color-dark.fill path {
    fill: $color-black;
  }
  .icon-color-error.fill path {
    fill: $color-error;
  }
  .icon-color-gray.fill path {
    fill: $color-gray;
  }
  .icon-color-white.fill path {
    fill: $color-white;
  }
}
</style>
