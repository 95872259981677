var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-input",class:_vm.classObj},[(_vm.inputType == 'simple')?_c('div',[(
        !_vm.inputSpecs.conditional ||
          (_vm.inputSpecs.conditional && _vm.checkCondition(_vm.inputSpecs.conditional))
      )?_c('FormulateInput',_vm._b({attrs:{"label-has-errors-class":"has-errors"}},'FormulateInput',_vm.inputSpecs.props,false)):_vm._e()],1):_vm._e(),(_vm.inputType == 'multicolumn')?_c('div',{staticClass:"multicolumn"},_vm._l((_vm.inputSpecs.items),function(item){return _c('div',{key:item.name,staticClass:"cluster-input",class:item.flexible},[(
          !item.conditional ||
            (item.conditional && _vm.checkCondition(item.conditional))
        )?_c('FormulateInput',_vm._b({attrs:{"label-has-errors-class":"has-errors"}},'FormulateInput',item.props,false)):_vm._e()],1)}),0):_vm._e(),(_vm.inputType == 'cluster')?_c('div',[(!_vm.inputSpecs.conditional || _vm.checkCondition(_vm.inputSpecs.conditional))?_c('FormulateInput',_vm._b({attrs:{"type":"group"},scopedSlots:_vm._u([{key:"default",fn:function({ index }){return _vm._l((_vm.inputSpecs.clusterItems),function(item){return _c('div',{key:item.props.name,staticClass:"cluster-input",class:item.flexible},[_c('FormulateInput',_vm._b({attrs:{"label-has-errors-class":"has-errors"},on:{"blur":_vm.blurHandler}},'FormulateInput',item.props,false)),(item.conditional && _vm.checkCondition(item.conditional, index))?_c('FormulateInput',_vm._b({attrs:{"label-has-errors-class":"has-errors"}},'FormulateInput',item.conditional.props,false)):_vm._e()],1)})}}],null,false,2127531504)},'FormulateInput',_vm.inputSpecs.cluster.props,false)):_vm._e()],1):_vm._e(),(_vm.inputType == 'map')?_c('div',[_c('div',{staticClass:"mapcomp"},[_c('BaseMap')],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }