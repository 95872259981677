import Vue from "vue";
import Vuex from "vuex";
import firebaseConfig from "./../../firebaseconfig.js";
import emailconfig from "./../../emailconfig.js";
import fb from "./lib_firebase.js";
import axios from "axios";
import formspecs from "./formspecs.js";
import _ from "lodash";

let currentEmailconfig = _.cloneDeep(emailconfig); 

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    userData: {},
    userReports: [],
    loading: true,
    authChecked: false,
    formspecs: formspecs.fields,
    formspecsGroups: formspecs.groups,
    unsavedData: false,
    currentReportCode: "",
    functionsUrl: process.env.VUE_APP_USE_EMULATORS == 'true' ? `http://localhost:5001/${firebaseConfig.projectId}/us-central1` : `https://us-central1-${firebaseConfig.projectId}.cloudfunctions.net`
  },
  mutations: {
    setLoading(state, loading) {
      state.loading = loading;
    },

    setUser(state, user) {
      Vue.set(state, "user", user);
      // console.log("user set", user);
    },

    setUserData(state, userData) {
      // state.userData = userData;
      Vue.set(state, "userData", { ...userData });
      // console.log("userData set", userData);
    },

    setAuthChecked(state) {
      state.authChecked = true;
    },

    setUserReports(state, userReports) {
      // transform a key-value object in array,
      // loosing firebase generated uuid,
      // state.userReports contains just the most recent issue of each report
      state.userReports.splice(0);

      Object.keys(userReports).forEach(key => {
        let currentReportIndex = state.userReports.findIndex(
          el => el.report_uid == userReports[key].report_uid
        );
        if (currentReportIndex >= 0) {
          let date = new Date(userReports[key].timestamp);
          let currentDate = new Date(
            state.userReports[currentReportIndex].timestamp
          );
          //keep the most recent issue of each report
          if (date > currentDate) {
            state.userReports.splice(currentReportIndex, 1, userReports[key]);
          }
        } else {
          state.userReports.push(userReports[key]);
        }
      });
      // console.log("setUserReports set", state.userReports);
    },
    setCurrentReportCode(state, code) {
      state.currentReportCode = code;
    }
  },
  actions: {
    setLoading({ commit }, loading) {
      return commit("setLoading", loading);
    },
    login({ commit, dispatch }, loginObj) {
      commit("setLoading", true);
      return fb
        .login(loginObj.email, loginObj.password)
        .then(res => {
          let user = res.user;
          commit("setUser", user);
          dispatch("getUserReports");
          dispatch("setUserDataListener");
          commit("setLoading", false);
        })
        .catch(err => {
          commit("setUser", null);
          console.error("login error", err);
          throw err;
        });
    },
    loginUser({ state, commit }, username) {
      commit("setLoading", true);
      return axios
        .post(`${state.functionsUrl}/usernameExists`, { username: username })
        .then(res => {
          commit("setLoading", false);
          return res.data;
        })
        .catch(err => {
          console.error("error while checking username/email existence", err);
        });
    },

    logout({ state, commit }) {
      commit("setLoading", true);
      if (!state.unsavedData) {
        return fb
          .logout()
          .then(() => {
            commit("setUser", null);
            commit("setLoading", false);
          })
          .catch(err => {
            console.error("logout error", err);
          });
      }
    },

    signup({ dispatch, commit }, signupObj) {
      commit("setLoading", true);
      return fb
        .signup(signupObj.email, signupObj.password)
        .then(res => {
          let user = res.user;
          commit("setUser", user);
          ["password", "password_confirm"].forEach(key => {
            delete signupObj[key];
          });
          fb.registerUser(signupObj).then(() => {
            dispatch("setUserDataListener");
            commit("setLoading", false); //getting user data in background
          });
        })
        .catch(err => {
          commit("setUser", null);
          console.error("signup error", err);
          commit("setLoading", false);
          throw err;
        });
    },

    setUserDataListener({ commit }) {
      let ref = fb.getUserRef();
      ref.on("value", snapshot => {
        commit("setUserData", snapshot.val());
      });
    },

    updateUserData({ commit }, data) {
      commit("setLoading", true);
      return fb
        .updateUserData(data)
        .then(() => {
          commit("setLoading", false);
        })
        .catch(err => {
          console.error("update profile error", err);
        });
    },

    clearUserData({ commit }) {
      commit("setUserData", null);
      fb.detachAllListeners();
    },

    submitReport({ state, commit }, data) {
      commit("setLoading", true);
      return fb
        .postReport(data)
        .then(() => {
          // console.log("data", _.cloneDeep(data), state);
          currentEmailconfig = _.cloneDeep(emailconfig);
          commit("setLoading", false);

          if (data.stato == "A" || data.stato == "AP") {
            let rs = state.userData.ragione_sociale;
            currentEmailconfig.html = ""; //useless, just to avoid an error
            let completeTo = [state.user.email, state.userData.responsabile_ambientale_email];
            let singleTo = [currentEmailconfig.to]
            currentEmailconfig.to = singleTo;
            currentEmailconfig.subject =
              `PIA Copernico: ${data.data} ${rs}` +
              (data.luogo[0].localita ? `, ${data.luogo[0].localita}` : ``);
            axios
              .post(`${state.functionsUrl}/sendReportEmail`, {
                ...currentEmailconfig,
                report: {
                  ...data,
                  ragione_sociale: rs,
                },
                user_data: state.userData
                // report: JSON.stringify(data)
              })
              .then(() => {

                currentEmailconfig.to = completeTo;
                currentEmailconfig.secondEmail = true;
                axios
                  .post(`${state.functionsUrl}/sendReportEmail`, {
                    ...currentEmailconfig,
                    report: {
                      ...data,
                      ragione_sociale: rs,
                    },
                    user_data: state.userData
                    // report: JSON.stringify(data)
                  })
                  .then(() => {
                    currentEmailconfig.secondEmail = false
                  })
              })
              .catch(err => {
                currentEmailconfig.secondEmail = false
                // handle error
                console.error(
                  "error sending email to notify report submission",
                  err
                );
              });
          }
        })
        .catch(err => {
          console.error("error while posting report", err);
        });
    },

    deleteReport({ commit }, uid) {
      commit("setLoading", true);
      return fb.deleteReport(uid).then(() => {
        console.log("report", uid, " removed!");
        commit("setLoading", false);
      });
    },

    setAuthChecked({ commit }) {
      commit("setAuthChecked");
      commit("setLoading", false);
    },

    autoLoginHelper({ commit, dispatch }) {
      let user = fb.getCurrentUser();
      commit("setUser", user);
      dispatch("getUserReports");
      dispatch("setUserDataListener");
    },

    autoLogoutHelper({ commit }) {
      commit("setUser", null);
    },

    getUserReports({ commit }) {
      fb.getUserReports(snapshot => {
        let data = snapshot.val();
        // console.log("getUserReports data", data)
        commit("setUserReports", data ? data : {});
      });
    },
    setCurrentReportCode({ commit }, data) {
      commit("setCurrentReportCode", data);
    },
    sendPasswordResetEmail({ commit }, email) {
      commit("setLoading", true);
      fb.sendPasswordResetEmail(email)
        .then(() => {
          location.reload();
        })
        .catch(err => {
          console.error("error while sending password reset email", err);
        });
    }
  },
  getters: {
    isAuthenticated: state => {
      return state.user != null;
    },

    isLoading: state => {
      return state.loading;
    },

    formspecsByForm: state => form => {
      let rtn = [];
      state.formspecs
        .sort((a, b) => {
          return a.order - b.order;
        })
        .forEach(el => {
          if (el.forms.includes(form)) {
            rtn.push(el);
          }
        });
      return rtn;
    },

    formspecsByFormGrouped: (state, getters) => form => {
      let fields = getters.formspecsByForm(form);
      console.log('form', form)
      let rtn = [];
      fields.forEach(field => {
        let groupId = field.groupId;
        if (groupId == undefined) {
          console.error("form spec does not contain groupId");
          return;
        }
        let group = getters.formspecsGroups.find(group => group.id == groupId);
        if (!group) {
          console.error("form spec has not-defined groupId");
          return;
        }
        let groupIndex = rtn.findIndex(el => el.name == group.name);
        if (groupIndex < 0) {
          rtn.push({ name: group.name, fields: [field], order: group.order });
        } else {
          rtn[groupIndex].fields.push(field);
        }
      });
      rtn.sort((a, b) => {
        return a.order - b.order;
      });
      return rtn;
    },

    formspecsGroups: state => {
      return state.formspecsGroups;
    },

    user: state => {
      return state.user;
    },

    userData: state => {
      return state.userData;
    },

    authChecked: state => {
      return state.authChecked;
    },

    userReports: state => {
      return state.userReports;
    },

    getUrlFromStorageRef: () => ref => {
      return fb.storageRefToURL(ref);
    },
    currentReportCode: state => state.currentReportCode
  }
});
