<template>
  <div id="app">
    <Modal />
    <Loading />
    <router-view />
  </div>
</template>

<script>
import fb from "./js/lib_firebase.js";
import Loading from "./components/InterfaceLoading.vue";
import Modal from "./components/InterfaceModal.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "App",
  components: { Loading, Modal },
  computed: {
    ...mapGetters(["authChecked"])
  },
  mounted() {
    // listen to every change of the current user,
    // if not logged in, go to login page
    fb.setOnAuthStateChangedHandler(user => {
      //toggle just on first load
      if (!this.authChecked) this.setAuthChecked();

      if (user) {
        this.autoLoginHelper();
        if (this.$route.name != "home") this.$router.push({ name: "home" });
      } else {
        this.autoLogoutHelper();
        if (this.$route.name != "login") this.$router.push({ name: "login" });
      }
    });
  },
  methods: {
    ...mapActions(["setAuthChecked", "autoLoginHelper", "autoLogoutHelper"])
  }
};
</script>

<style lang="scss" src="@/css/main.scss"></style>
