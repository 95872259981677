<template>
  <transition name="fade">
    <div v-show="isLoading" id="loading">
      <Spinner size="large" :line-fg-color="fgcolor" />
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
import Spinner from "vue-simple-spinner";

export default {
  name: "Loading",
  components: { Spinner },
  data() {
    return {
      flip: true
    };
  },
  computed: {
    ...mapGetters(["isLoading"]),
    fgcolor() {
      return "#CECECE";
    }
  }
};
</script>

<style scoped lang="scss">
#loading {
  position: fixed;
  top: 0px;
  width: 100vw;
  height: 100vh;
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-background-overlay;
  /* experimental features must be enabled in chrome for the backdrop filter to work */
  backdrop-filter: $background-blur-main;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
