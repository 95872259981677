<template>
  <div id="form" class="page">
    <div class="header" :class="headerClassObj">
      <div class="header-text-container">
        <div class="header-text">
          <p>{{ title }}</p>
        </div>
        <div v-if="swiperReady" class="header-text">
          {{ swiperIndex + 1 }} di {{ swiperLength }}
        </div>
      </div>
      <div v-if="swiperReady" class="header-pagination">
        <div class="header-pagination-cursor" :style="cursorStyle"></div>
      </div>
    </div>
    <div class="body">
      <div class="wrapper">
        <div class="buttons-container">
          <div class="buttons-wrapper">
            <BaseIcon
              name="back"
              :class="{ disabled: isFirstGroup }"
              tip="indietro"
              @clicked="back"
            />
            <BaseIcon name="close" tip="chiudi" tip-left @clicked="discard" />
          </div>
        </div>
        <FormulateForm
          v-model="formValues"
          name="current"
          class="info-container"
          @submit="submitHandler"
          @validation="validationHandler"
          @submit-raw="submitRawHandler"
        >
          <div
            v-for="(group, index) in formspecs"
            :key="group.name"
            class="info-slide"
          >
            <transition :name="transitionType[index]">
              <div v-show="index == swiperIndex" class="info-slide--content">
                <div class="info-slide--content-limited">
                  <h2>{{ group.name }}</h2>
                  <template v-if="formName == 'profile'">
                    <BaseInput
                      v-for="formitem in group.fields"
                      :key="formitem.name"
                      :input-specs="formitem"
                      :input-type="formitem.type"
                      :form-values="formValues"
                    />
                  </template>
                  <template v-else>
                    <template v-if="prefillData == undefined">
                      <BaseInput
                        v-for="formitem in group.fields"
                        :key="formitem.name"
                        :input-specs="formitem"
                        :input-type="formitem.type"
                        :form-values="formValues"
                      />
                    </template>
                    <template v-else>
                      <template
                        v-if="group.fields.filter(f => f.cluster).length > 0"
                      >
                        <BaseInput
                          v-for="formitem in group.fields"
                          :key="formitem.name"
                          :input-specs="formitem"
                          :input-type="formitem.type"
                          :form-values="formValues"
                        />
                      </template>
                      <template v-else>
                        <BaseInput
                          v-for="formitem in group.fields.filter(
                            f =>
                              f.items || (f.props && f.props.type !== 'image')
                          )"
                          :key="formitem.name"
                          :input-specs="formitem"
                          :input-type="formitem.type"
                          :form-values="formValues"
                        />
                        <template
                          v-if="
                            group.fields.filter(
                              f => f.props && f.props.type == 'image'
                            ).length > 0
                          "
                        >
                          <template v-if="prefillData !== undefined">
                            <template v-if="prefillData.pictures !== undefined">
                              <template
                                v-if="
                                  prefillData.pictures.length ==
                                    formImages.length
                                "
                              >
                                <FormulateInput
                                  type="image"
                                  label="Foto"
                                  placeholder="Foto"
                                  multiple="true"
                                  name="pictures"
                                  upload-behavior="delayed"
                                  :value="formImages"
                                  :validation="group.fields.filter(f => f.props && f.props.type == 'image').map(g => g.props)[0].validation"
                                />
                              </template>
                            </template>
                            <template v-else>
                              <FormulateInput
                                type="image"
                                label="Foto"
                                name="pictures"
                                placeholder="Foto"
                                multiple="true"
                                upload-behavior="delayed"
                                :validation="group.fields.filter(f => f.props && f.props.type == 'image').map(g => g.props)[0].validation"
                              />
                            </template>
                          </template>
                        </template>
                      </template>
                    </template>
                  </template>
                </div>
                <div v-if="!isLastGroup" class="buttons-container">
                  <div class="buttons-wrapper next">
                    <BaseIcon
                      name="next"
                      :class="{ disabled: isLastGroup }"
                      color="main"
                      tip="avanti"
                      tip-left
                      @clicked="next"
                    />
                  </div>
                </div>
                <FormulateInput
                  v-if="isLastGroup"
                  type="submit"
                  :label="submitButton.label"
                  class="size-medium"
                />
              </div>
            </transition>
          </div>
        </FormulateForm>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import _ from "lodash";

export default {
  name: "Form",

  props: {
    formName: {
      type: String,
      default: "profile",
      validator: function(value) {
        return (
          ["attivazione", "profile", "signup", "preliminare"].indexOf(value) !==
          -1
        );
      }
    },
    title: {
      type: String,
      default: ""
    },
    submitButton: {
      type: Object,
      default: function() {
        return {
          label: "submit"
        };
      }
    },
    allowDraft: {
      type: Boolean,
      default: false
    },
    prefillData: {
      type: Object,
      default: undefined,
      validator: function(val) {
        return val["report_uid"] != undefined;
      }
    },
    headerColor: {
      type: String,
      default: "gray",
      validator: function(value) {
        return ["error", "gray", "main", "dark"].indexOf(value) !== -1;
      }
    },
    forceSubObj: {
      type: Object,
      // required: true
      default: function() {
        return {
          flag: false,
          payload: null
        };
      }
    }
  },

  data() {
    return {
      formValues: {},
      swiperIndex: 0,
      transitionType: [],
      firstFormImages: [],
      validations: [],
      isForced: false,
      inhibitNext: false
    };
  },

  computed: {
    ...mapGetters([
      "formspecsByForm",
      "formspecsGroups",
      "formspecsByFormGrouped",
      "userData",
      "getUrlFromStorageRef"
    ]),
    formImages() {
      return this.firstFormImages;
    },
    formspecsByFormGroupedCache() {
      return this.formspecsByFormGrouped(this.formName);
    },
    formspecs() {
      let rtn = _.cloneDeep(this.formspecsByFormGroupedCache);
      //rearrange for multicolumns and extra-required validation
      let multicolumns = [];
      rtn.forEach(group => {
        group.fields.forEach((input, index) => {
          //extra-required stuff
          if (
            !input.type ||
            input.type == "simple" ||
            input.type == "multicolumn"
          ) {
            if (input.required) {
              input.required.forEach(form => {
                if (form == this.formName) {
                  this.setExtraRequired(input.props);
                }
              });
            }
          } else if (input.type == "cluster") {
            input.clusterItems.forEach(item => {
              if (item.required) {
                item.required.forEach(form => {
                  if (form == this.formName) {
                    this.setExtraRequired(item.props);
                  }
                });
              }
              if(item.props.name == 'Sede di riferimento'){
                let altreSediOperative = this.userData.altre_sedi_operative ? this.userData.altre_sedi_operative.map(el => el.localita) : [];
                item.props.options = this.userData.indirizzo_sede_legale?.map(el => el.localita).concat(altreSediOperative);
              }
            });
          }
          //multicolumn stuff
          if (input.type == "multicolumn") {
            let obj = multicolumns.find(el => el.name == input.multicolumnId);
            if (obj) {
              obj.items.push(input);
            } else {
              let newobj = {
                name: input.multicolumnId,
                type: "multicolumn",
                groupName: group.name,
                index: index,
                items: [input]
              };
              multicolumns.push(newobj);
            }
          }
        });
      });
      let count = 0;
      rtn.forEach(group => {
        multicolumns.forEach(mc => {
          if (group.name == mc.groupName) {
            group.fields.splice(mc.index + count, 0, mc);
            count++;
          }
        });
      });
      multicolumns.forEach(mc => {
        mc.items.forEach(item => {
          let gr = rtn.find(grp => grp.name == mc.groupName);
          let i = gr.fields.findIndex(el => el.name == item.name);
          gr.fields.splice(i, 1);
        });
      });
      return rtn;
    },
    formspecsUngrouped() {
      return this.formspecsByForm(this.formName);
    },
    swiperReady() {
      return true;
    },
    isLastGroup() {
      return this.swiperIndex == this.swiperLength - 1;
    },
    isFirstGroup() {
      return this.swiperIndex == 0;
    },
    cursorWidth() {
      return 100 / this.swiperLength;
    },
    cursorLeft() {
      return this.cursorWidth * this.swiperIndex;
    },
    swiperLength() {
      return this.formspecs.length;
    },
    cursorStyle() {
      return {
        width: this.cursorWidth.toString() + "%",
        marginLeft: this.cursorLeft.toString() + "%"
      };
    },
    headerClassObj() {
      return {
        main: this.headerColor == "main",
        error: this.headerColor == "error",
        dark: this.headerColor == "dark"
      };
    }
  },

  watch: {
    forceSubObj: {
      handler(n) {
        if (n.flag) {
          this.forceSubmission(n.payload);
        }
      },
      deep: true,
      immediate: true
    },
    swiperIndex() {
      let formDiv = document.querySelector(".info-container");
      formDiv.scrollTop = 0;
    }
  },

  mounted() {
    this.initTransitions();

    this.$root.$on("located", this.locatedHandler);
    this.$root.$on("cleared", this.clearedHandler);

    if (this.formName == "profile") {
      // prefill the form with user data from firebase
      this.prefill(this.userData);
    } else if (
      this.formName == "attivazione" ||
      this.formName == "preliminare"
    ) {
      if (this.prefillData) {
        // prefill the form with report data
        this.prefill(this.prefillData);
      }
      if (this.formName == "preliminare") this.prefillFromDevice();
    }
  },

  beforeDestroy() {
    this.$root.$off("located", this.locatedHandler);
    this.$root.$off("cleared", this.clearedHandler);
  },

  methods: {
    discard() {
      this.$emit("close", this.formValues);
      // this.$formulate.submit("current");
    },
    forceSubmission() {
      // this.formValues = payload;
      this.isForced = true;
      this.$formulate.submit("current");
    },
    submitHandler(data) {
      // console.log("regular submitHandler in InterfaceForm",_.cloneDeep(data))
      if (!this.inhibitNext) {
        this.$emit("submit", data);
      } else {
        this.inhibitNext = false;
      }
    },
    back() {
      if (this.swiperIndex > 0) {
        this.transitionType[this.swiperIndex] = "to-right";
        this.swiperIndex--;
        setTimeout(() => {
          this.updateTransitions();
        }, 500);
      }
    },
    next() {
      if (this.swiperIndex < this.swiperLength - 1) {
        this.transitionType[this.swiperIndex] = "to-left";
        this.swiperIndex++;
        setTimeout(() => {
          this.updateTransitions();
        }, 500);
      }
    },
    prefill(data) {
      let dataClone = {};
      dataClone = _.cloneDeep(data);
      this.firstFormImages = [];
      Object.keys(dataClone).forEach(key => {
        let spec = this.formspecsUngrouped.find(el => el.name == key);
        if (spec) {
          if (key == "pictures") {
            // console.log("------- PICTURES -----------", dataClone[key]);
            let arrPictures = [];
            dataClone[key].forEach(pic => {
              this.getUrlFromStorageRef(pic[0].url).then(url => {
                let currentPicUrl = url;
                let lastName = _.cloneDeep(pic[0].name);
                arrPictures.push({
                  url: currentPicUrl,
                  relativeUrl: pic[0].url,
                  name: lastName
                });
              });
            });
            this.firstFormImages = arrPictures;
          } else {
            this.$set(this.formValues, key, dataClone[key]);
          }
        }
      });
      // just for map component
      this.$root.$emit("prefilled", {
        new: false,
        luogo: this.formValues.luogo
      });
    },
    updateTransitions() {
      this.transitionType.forEach((slide, index) => {
        if (index > this.swiperIndex) {
          slide = "to-right";
        } else if (index <= this.swiperIndex) {
          slide = "to-left";
        }
      });
    },
    setExtraRequired(props) {
      props.validation = "required|" + props.validation;
    },
    validationHandler(data) {
      // console.log("validation changed", data);
      let index = this.validations.findIndex(el => el.name == data.name);
      if (data.hasErrors) {
        if (index < 0) {
          this.validations.push(data);
        } else {
          this.validations.splice(index, 1, data);
        }
        this.validations.sort(this.sortInputsByOrder);
      } else {
        if (index >= 0) {
          this.validations.splice(index, 1);
        }
      }
    },
    submitRawHandler(data) {
      let form = data.form;
      if (this.isForced) {
        // console.log("FORCED SUBMIT")
        this.isForced = false;

        const pending = [];
        let values = _.cloneDeep(form.proxy);
        values = {
          ...values,
          ...this.forceSubObj.payload
        };
        for (const key in values) {
          // SB: risky check! state that the object is a FileUpload
          // by checking the presence of .fileList property
          // the following code not working:
          //
          // import FileUpload from '@braid/vue-formulate/src/FileUpload.js';
          // [...] form.proxy[key] instanceof FileUpload
          //
          if (typeof form.proxy[key] === "object" && form.proxy[key].fileList) {
            pending.push(
              form.proxy[key]
                .upload()
                .then(data => Object.assign(values, { [key]: data }))
                .then(v => {
                  return this.submitHandler(v);
                })
            );
          }
        }
        if (!pending.length) {
          this.submitHandler(values);
          this.inhibitNext = true;
        }
      } else {
        if (this.validations.length) {
          // //SB: whit confirmation modal
          // this.$root.$emit("modal", {
          //   color: "error",
          //   fullscreen: true,
          //   alignLeft: true,
          //   title:
          //     "La segnalazione non può essere inviata in quanto mancano delle informazioni fondamentali.",
          //   confirm: () => {
          //     this.$emit("save", this.formValues);
          //   },
          //   undo: () => {
          //     this.swiperIndex = this.getGroupIndexByName(
          //       this.validations[0].name
          //     );
          //     setTimeout(() => {
          //       this.initTransitions();
          //     }, 500);
          //   },
          //   confirmLabel: "salva draft",
          //   colorConfirm: {
          //     color: "white",
          //     transparent: true
          //   },
          //   undoLabel: "correggi",
          //   colorUndo: {
          //     color: "dark",
          //     transparent: false
          //   }
          // });

          // without confirmation modal
          this.swiperIndex = this.getGroupIndexByName(this.validations[0].name);
          setTimeout(() => {
            this.initTransitions();
          }, 500);
        }
      }
    },
    sortInputsByOrder(a, b) {
      return (
        this.getOrderIndexByName(a.name) - this.getOrderIndexByName(b.name)
      );
    },
    getOrderIndexByName(name) {
      let rtn = 0;
      this.formspecsByFormGroupedCache.forEach((group, groupIndex) => {
        group.fields.forEach((field, fieldIndex) => {
          if (field.name == name) rtn = 1000 * groupIndex + 1 * fieldIndex;
        });
      });
      return rtn;
    },
    getGroupIndexByName(name) {
      let rtn = undefined;
      this.formspecsByFormGroupedCache.forEach((group, groupIndex) => {
        group.fields.forEach(field => {
          if (field.name == name) rtn = groupIndex;
        });
      });
      return rtn;
    },
    initTransitions() {
      if (this.transitionType.length) {
        this.transitionType.forEach((transition, index) => {
          this.$set(
            this.transitionType,
            index,
            index > this.swiperIndex ? "to-right" : "to-left"
          );
        });
      } else {
        this.formspecs.forEach((group, index) => {
          this.transitionType.push(index ? "to-right" : "to-left");
        });
      }
    },
    prefillFromDevice() {
      let now = new Date();
      if (!this.formValues["ora"]) {
        let hour = Intl.DateTimeFormat("it-IT", {
          hour: "2-digit",
          minute: "2-digit"
        }).format(now);
        this.$set(this.formValues, "ora", hour);
      }
      if (!this.formValues["data"]) {
        let date =
          Intl.DateTimeFormat("en-US", { year: "numeric" }).format(now) +
          "-" +
          Intl.DateTimeFormat("en-US", { month: "2-digit" }).format(now) +
          "-" +
          Intl.DateTimeFormat("en-US", { day: "2-digit" }).format(now);
        this.$set(this.formValues, "data", date);
      }
      // just for map component
      this.$root.$emit("prefilled", {
        new: true,
        luogo: this.formValues.luogo
      });
    },
    setCoordinates(coord) {
      if (coord) {
        let lat = coord[0];
        let lng = coord[1];

        if (!this.formValues["luogo"]) {
          this.$set(this.formValues, "luogo", []);
        }
        if (!this.formValues["luogo"].length) {
          let obj = { latitudine: lat, longitudine: lng };
          this.formValues["luogo"].push(obj);
        } else {
          this.$set(this.formValues["luogo"][0], "latitudine", lat);
          this.$set(this.formValues["luogo"][0], "longitudine", lng);
        }
      } else {
        if (
          this.formValues["luogo"] &&
          this.formValues["luogo"][0].latitudine &&
          this.formValues["luogo"][0].longitudine
        ) {
          this.$delete(this.formValues["luogo"][0], "latitudine");
          this.$delete(this.formValues["luogo"][0], "longitudine");
          this.$;
        }
      }
    },
    locatedHandler(coord) {
      this.setCoordinates(coord);
    },
    clearedHandler() {
      this.setCoordinates(null);
    }
  }
};
</script>

<style scoped lang="scss">
#form {
  .info-slide--content {
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
      margin-bottom: 0.5em;
      margin-bottom: 1em;
    }
  }
}
</style>
